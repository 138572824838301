import { set, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { PutElementV1 } from "../../../services";
import { StyleInput2, StyleInputError } from "../../../features/Constantes";
import { Oval } from "react-loader-spinner";
import NumberFormat from "react-number-format";
import { MailsRegExp } from "../../../services/Utilitario";
import React, { useEffect, useState } from "react";
import { getListV3 } from "../../../services";
import { toast } from "react-toastify";
import { Switch } from "@headlessui/react";
import { ButtonDefault } from "../../../features/components/ButtonDefault";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router";
import { useCookies } from "react-cookie";

const InfoUsuario = () => {
  const usuario = useSelector((store) => store.app.user);
  const [IsLoading, setIsLoading] = useState(false);
  const [enabledConta, setEnabledConta] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSwitchObligado = () => {
    setEnabledConta(!enabledConta);
    setValue("resetearContra", enabledConta ? false : true);
  };

  const cerrarSesion = async () => {
    const logoutUrl = `${auth.settings.authority}connect/endsession?id_token_hint=${auth.user?.id_token}&post_logout_redirect_uri=${auth.settings.post_logout_redirect_uri}&state=restablecer`;
    sessionStorage.clear();
    localStorage.clear();

    await new Promise((resolve) => {
      window.location.href = logoutUrl;
      resolve();
    });
  };

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
    (async () => {
      const res = await getListV3(`api/Contribuyente/SearchContribuyenteRuc`, {
        identificacion: usuario.usuario,
      });
      setValue("razonSocial", res.nombre);
      setValue("RUC", res.identificacion);
      setValue("correo", res.correo);
    })();
  }, []);

  const [isResetPassword, setIsResetPassword] = useCookies(["isResetPassword"]);

  const [isResetPasswordL, setIsResetPasswordL] = useState(false);

  const hadleSubmitResetPassword = async () => {
    setIsResetPasswordL(true);
    try {
      let empresaValues = {
        RazonSocial: getValues().razonSocial,
        Identificacion: getValues().RUC,
        Correo: getValues().correo,
        ResetearContra: true,
      };

      const respuestaPut = await PutElementV1(
        `api/Contribuyente/EditarContribuyenteRuc`,
        empresaValues
      );

      if (respuestaPut.data) {
        setIsResetPassword("isResetPassword", true);
        setIsResetPasswordL(false);
        cerrarSesion();
      }
    } catch (ex) {
      console.log(ex);
      setIsResetPasswordL(false);
    }
  };

  const handleSubmitEmpresa = async (event) => {
    try {
      event.preventDefault();

      let empresaValues = {
        RazonSocial: getValues().razonSocial,
        Identificacion: getValues().RUC,
        Correo: getValues().correo,
        ResetearContra: enabledConta,
      };

      const respuestaPut = await PutElementV1(
        `api/Contribuyente/EditarContribuyenteRuc`,
        empresaValues
      );

      const promiseToast = () =>
        new Promise((resolve) =>
          setInterval(() => resolve(respuestaPut), 3000)
        );

      await toast.promise(promiseToast(), {
        pending: {
          render() {
            setIsLoading(true);
            return "Guardando...";
          },
          isLoading: true,
        },
        success: {
          render() {
            setIsLoading(false);
            return "Datos actualizados";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
        error: {
          render() {
            setIsLoading(false);
            return "Algo ha sucedido, intente nuevamente";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
      });
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="space-y-6 xl:mx-4 my-2">
        <div className="bg-white shadow  dark:bg-gray-900 sm:rounded-lg sm:p-6">
          <div>
            <div className="md:grid md:grid-cols-2 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium dark:text-white leading-6 text-black">
                  Perfil de usuario
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Esta información se mostrará públicamente
                </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white"
                    >
                      Identificación
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm ">
                      <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          className="w-5 h-5 fill-gray-500 dark:fill-white"
                        >
                          <path d="M336 64h-80a64 64 0 0 0-128 0H48a48 48 0 0 0-48 48v352a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V112a48 48 0 0 0-48-48zM192 40a24 24 0 1 1-24 24 24 24 0 0 1 24-24zm144 418a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h42v36a12 12 0 0 0 12 12h168a12 12 0 0 0 12-12v-36h42a6 6 0 0 1 6 6zm-99.2-106h-5a103.25 103.25 0 0 1-79.7 0h-5c-37.01 0-67.1 25.79-67.1 57.6v6.4a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-6.4c0-31.81-30.09-57.6-67.2-57.6zM192 336a64 64 0 1 0-64-64 64 64 0 0 0 64 64z" />
                        </svg>
                      </span>
                      <input
                        readOnly
                        type="text"
                        name="RUC"
                        className={StyleInput2 + " bg-gray-200 dark:bg-gray-600"}
                        placeholder="xxx-xxx-xxxxxxxxx"
                        {...register("RUC", {
                          required: {
                            value: true,
                            message: "Nombre es requerido",
                          },
                          maxLength: {
                            value: 13,
                            message: "No más de 13 carácteres!",
                          },
                          minLength: {
                            value: 13,
                            message: "Mínimo 13 carácteres",
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white"
                    >
                      Razón social
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                          className="w-5 h-5 fill-gray-500 dark:fill-white"
                        >
                          <path d="M630.6 364.9l-90.3-90.2c-12-12-28.3-18.7-45.3-18.7h-79.3c-17.7 0-32 14.3-32 32v79.2c0 17 6.7 33.2 18.7 45.2l90.3 90.2c12.5 12.5 32.8 12.5 45.3 0l92.5-92.5c12.6-12.5 12.6-32.7.1-45.2zm-182.8-21c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24c0 13.2-10.7 24-24 24zM48 463.8v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 7.7 0 15.1 1.3 22.2 3.3v-49c-7.3-1.2-14.6-2.2-22.2-2.2-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 303.9 0 364 0 438.3v25.6c0 26.5 21.5 48 48 48h352c9.7 0 18.7-2.9 26.3-7.9l-40.1-40.1H48zm176-175.9c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.4 80 144c0 79.5 64.5 143.9 144 143.9zM224 48c52.9 0 96 43 96 96 0 52.9-43.1 96-96 96s-96-43.1-96-96c0-53 43.1-96 96-96z" />
                        </svg>
                      </span>
                      <input
                        readOnly
                        type="text"
                        id="razonSocial"
                        name="razonSocial"
                        className={StyleInput2 + " bg-gray-200 dark:bg-gray-600"}
                        placeholder="xxx-xxx-xxxxxxxxx"
                        {...register("razonSocial", {
                          required: {
                            value: true,
                            message: "Nombre es requerido",
                          },
                          maxLength: {
                            value: 300,
                            message: "No más de 300 carácteres!",
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white"
                    >
                      Correo
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="w-5 h-5 fill-gray-500 dark:fill-white"
                        >
                          <path d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z" />
                        </svg>
                      </span>
                      <input
                        type="text"
                        id="correo"
                        name="correo"
                        className={StyleInput2}
                        {...register("correo", {
                          required: {
                            value: true,
                            message: "Nombre es requerido",
                          },
                          maxLength: {
                            value: 300,
                            message: "No más de 300 carácteres!",
                          },
                          minLength: {
                            value: 4,
                            message: "Mínimo 4 carácteres",
                          },
                          pattern: MailsRegExp,
                        })}
                        maxLength="300"
                      />
                    </div>
                  </div>

                  <div className="relative z-0 mb-6 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white mb-1 "
                    >
                      Resetear Contraseña
                    </label>

                    <button
                      onClick={hadleSubmitResetPassword}
                      disabled={isResetPasswordL}
                      className={` bg-green-500 gap-x-1 text-sm lg:w-auto md:w-auto w-full flex items-center text-white px-4 py-1.5 rounded-lg`}
                    >
                      {isResetPasswordL ? (
                        <svg
                          aria-hidden="true"
                          role="status"
                          className={`inline mr-3  animate-spin  w-4 h-4 `}
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                          />
                        </svg>
                      )}
                      Cambiar
                    </button>
                    {/* <Switch
                        checked={getValues().resetearContra}
                        onChange={handleSwitchObligado}
                        className={`${
                          enabledConta ? "bg-blue-600" : "bg-gray-400"
                        } relative inline-flex flex-shrink-0 md:h-6 md:w-11 w-9 h-5  border-2 my-auto border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            enabledConta ? "translate-x-5" : "translate-x-0"
                          } pointer-events-none inline-block md:h-5 md:w-5 w-4 h-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch> */}
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  {IsLoading ? (
                    <button
                      disabled={IsLoading}
                      type="button"
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <Oval
                        height={18}
                        width={60}
                        color="#FFFFFF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#233876"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleSubmitEmpresa}
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Guardar
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoUsuario;