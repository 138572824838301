import React from "react";
import ModalComponent from "../../features/components/ModalComponets/ModalComponent";

const ModalInfo = ({
    isOpen, toggleModal
}) => {
    return (

    <ModalComponent
    isOpen={isOpen}
    onClose={toggleModal}
    >
      <>
          <p className="text-xl text-center font-bold text-blue-600">
        INTERMITENCIA SRI
       </p>

       <p className="text-lg">
        <b>Si eres agente de retención </b>, en estos momentos la recepción y aprobación de documentos electrónicos
        por parte del <b>SRI</b> se encuentra intermitente.
   </p>

      <p className="text-lg">
        Recuerda que puedes seguir creando y enviando tus documentos sin
       ningún problema, Intuito los enviará de forma automática al <b>SRI</b> cuando
         el servicio se normalice.
        </p>
     

   
      </>
    </ModalComponent>

  );
};
export default ModalInfo;