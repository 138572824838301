import React, { useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";

const Admin = (props) => {
  const [isPagesMenuOpen, setIsPagesMenuOpen] = useState(false);

  const [isPagesMenuOpen2, setIsPagesMenuOpen2] = useState(false);

  let activeClassName =
    "  w-full inline-block px-12 bg-[#5F7F93] truncate text-white    px-2 py-3 transition-colors duration-300 bg-red-500   dark:hover:text-gray-200";

  const menus = (codigo, index) => {
    switch (codigo) {
      case "25":
        return (
            <NavLink
              title="Editar Usuario"
              className={({ isActive }) =>
                isActive
                  ? activeClassName
                  : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
              }
              exact="true"
              to="/emision/reporte/editar-usuario"
            >
              Editar Usuario
            </NavLink>
        );
      case "27":
        return (
          <NavLink
            title="Establecimientos"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/reporte/establecimientos"
          >
            Establecimientos
          </NavLink>
        );

      case "12":
        return (
          <NavLink
            title="Reporte de contribuyentes"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/reporte/contribuyentes"
          >
            Contribuyentes{" "}
          </NavLink>
        );
      case "01":
        return (
          <NavLink
            title="Empresas"
            className={({ isActive }) =>
              isActive ? activeClassName : "w-full"
            }
            exact="true"
            to="/home"
          >
            Empresas
          </NavLink>
        );

      case "24":
        return (
          <NavLink
            title="Usuarios empresa"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors  hover:bg-[#5F7F93] hover:text-white truncate hover:  w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/reporte/usuarios"
          >
            Usuarios empresa
          </NavLink>
        );

      case "23":
        return (
          <NavLink
            title="Productos / Servicios"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white hover: truncate   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/reporte/servicios"
          >
            Productos / Servicios
          </NavLink>
        );

      case "29":
        return (
          <NavLink
            title="Editar empresa"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/reporte/empresa"
          >
            Editar empresa
          </NavLink>
        );
      case "30":
        return (
          <NavLink
            title="Lista de empresas"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors  hover:bg-[#5F7F93] hover:text-white truncate hover:  w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/reporte/lista-empresas"
          >
            Lista empresas
          </NavLink>
        );

      case "32":
        return (
          <NavLink
            title="Reporte a usuarios emisores"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors  hover:bg-[#5F7F93] hover:text-white truncate hover:  w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/reporte/doc-generado"
          >
            Reporte a usuarios emisores
          </NavLink>
        );

      default:
        return null;
    }
  };

  const hanldeclick = () => {
    setIsPagesMenuOpen(!isPagesMenuOpen);
  };

  const hanldeclick2 = () => {
    setIsPagesMenuOpen2(!isPagesMenuOpen2);
  };

  const renderSubMenu = (itemCode) => {
    return (
      <ul
        className={`mt-2 bg-[#003B5B] text-white overflow-hidden text-sm font-medium duration-1000 delay-300 rounded-md shadow-inner dark:text-gray-400 dark:bg-gray-900 ${
          !props.OpenMenu && "hidden"
        }`}
        aria-label="submenu"
      >
        {props.items.map((item, index) => (
          <li key={index}>{menus(item.codigo, index)}</li>
        ))}
      </ul>
    );
  };

  const renderSubMenu2 = (itemCode) => {
    return (
      <ul
        className={`p-2 mt-2  bg-[#003B5B]  overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900
          `}
        aria-label="submenu"
      >
        {props.items.map((item, index) => menus(item.codigo, index))}
      </ul>
    );
  };

  return (
    <ul
      className="pt-2 text-white"
      //title={!props.OpenMenu ? "Reportes emitidos" : ""}

      title={
        (props.modulo === "Administración") & !props.OpenMenu
          ? "Administración"
          : "" || (props.modulo === "Mantenimiento") & !props.OpenMenu
          ? "Mantenimiento"
          : "" || (props.modulo === "Base de datos") & !props.OpenMenu
          ? "Base de datos"
          : ""
      }
    >
      {props.OpenMenu ? (
        <Menu>
          <Menu.Button
            className={"w-full"}
            onClick={
              props.OpenMenu
                ? () => {
                    console.log("Clicked on:", props.modulo); // Añade logs para depuración
                    switch (props.modulo) {
                      case "Administración":
                        props.toggleItem("item5");
                        break;
                      case "Mantenimiento":
                        props.toggleItem("item4");
                        break;
                      case "Base de datos":
                        props.toggleItem("item3");
                        break;
                      default:
                        break;
                    }
                    hanldeclick();
                  }
                : null
            }
          >
            <li className=" text-sm flex  text-white justify-between items-center  hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <div className="flex gap-x-4 items-center">
                {props.modulo === "Administración" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                ) : (
                  <></>
                )}
                {props.modulo === "Mantenimiento" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.867 19.125h.008v.008h-.008v-.008z"
                    />
                  </svg>
                ) : (
                  <></>
                )}

                {props.modulo === "Base de datos" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                    />
                  </svg>
                ) : (
                  <></>
                )}

                <span
                  className={`${
                    !props.OpenMenu && "hidden"
                  } origin-left duration-200`}
                >
                  {props.modulo}
                </span>
              </div>
              <span
                className={`${
                  !props.OpenMenu && "hidden"
                } origin-left duration-200`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-4 h-4 ${
                    isPagesMenuOpen
                      ? "rotate-180 duration-300"
                      : "rotate-0 duration-300"
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </li>
          </Menu.Button>
        </Menu>
      ) : (
        <Menu>
          <Menu.Button className={"w-full lg:table-cell hidden"}>
            <li className="  text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              {props.modulo === "Administración" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              ) : (
                <></>
              )}

              {props.modulo === "Mantenimiento" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.867 19.125h.008v.008h-.008v-.008z"
                  />
                </svg>
              ) : (
                <></>
              )}
              {props.modulo === "Base de datos" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                  />
                </svg>
              ) : (
                <></>
              )}
            </li>
          </Menu.Button>
          {props.OpenMenu ? (
            <></>
          ) : (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className=" ml-16 -mt-[40px] z-50 absolute w-56 rounded-md bg-white text-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {props.items.map((item, index) => menus(item.codigo, index))}
                </div>
              </Menu.Items>
            </Transition>
          )}
        </Menu>
      )}

      {props.openMobil ? (
        <ul className="" onClick={hanldeclick2}>
          <li className=" text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
            {props.modulo === "Administracion" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            ) : (
              <></>
            )}
            {props.modulo === "Mantenimiento" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.867 19.125h.008v.008h-.008v-.008z"
                />
              </svg>
            ) : (
              <></>
            )}
            {props.modulo === "Base de datos" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                />
              </svg>
            ) : (
              <></>
            )}
            {props.modulo === "Administración" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            ) : (
              <></>
            )}

            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              {props.modulo}
            </span>
            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`w-4 h-4 ${
                  isPagesMenuOpen2
                    ? "rotate-180 duration-300"
                    : "rotate-0 duration-300"
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </li>
        </ul>
      ) : null}

      {/* <button onClick={props.OpenMenu ? hanldeclick : null} className="w-full">
      
    </button> */}
      {/* {isPagesMenuOpen ? (
        <ul
          className={` mt-2  bg-[#003B5B] text-white overflow-hidden text-sm font-medium  duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900 ${
            !props.OpenMenu && "hidden"
          }`}
          aria-label="submenu"
        >
          {props.items.map((item, index) => menus(item.codigo, index))}
        </ul>
      ) : null} */}

      {props.openItem === "item5" &&
        props.modulo === "Administración" &&
        renderSubMenu("item5")}
      {props.openItem === "item4" &&
        props.modulo === "Mantenimiento" &&
        renderSubMenu("item4")}
      {props.openItem === "item3" &&
        props.modulo === "Base de datos" &&
        renderSubMenu("item3")}

      {isPagesMenuOpen2 ? (
        <>
          <ul
            className={`p-2 mt-2  bg-[#003B5B]  overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900
          `}
            aria-label="submenu"
          >
            {props.items.map((item, index) => menus(item.codigo, index))}
          </ul>
        </>
      ) : null}
    </ul>
  );
};

export default Admin;
