import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../redux/app.slice";
import { useDispatch, useSelector } from "react-redux";
import { cargarPermisos } from "../AsideBar/asideBar.slice";
import { getList } from "../../services";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Oval } from "react-loader-spinner";
import SelectEmpresa from "./SelectEmpresa";
import UserIcon from "../../Icons/UserIcon";
import { useAuth } from "react-oidc-context";
import { ModalFirma } from "./Components/ModalFirma";
import { toast } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const NavBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth();
  const empresa = useSelector((store) => store.empresa.empresa);
  const usuario = useSelector((store) => store.app.user);
  const loginIn = useSelector((store) => store.app.loginIn);
  const iniciales = usuario.nombre
    .split(" ")
    .map((word) => word.charAt(0))
    .join("")
    .slice(0, 2);
  // console.log(iniciales);
  const [IsLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  // useEffect(() => {
   
  //   if(!loginIn){
  //    return 
  //   }
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     try {
      
  //       if (!(empresa._links === undefined)) {
  //         var permisos = await getList(
  //           empresa._links.find(
  //             (obj) => obj.descripcion === "obtener-permisos-empresa"
  //           ).enlace
  //         );
  //         dispatch(cargarPermisos(permisos._embedded));
  //         navigate("/");
  //         setIsLoading(false);
  //       } else {
         
  //         navigate("/emision/reporte/cliente")
  //         setIsLoading(false);
  //       }
  //     } catch (ex) {
  //       //console.log(ex);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
   

  //   // call the function
  //   fetchData()
  //     // make sure to catch any error
  //     .catch(console.error);
  // }, [loginIn,empresa]);

  const [isOpenModalFirma, setIsOpenModalFirma] = useState(false);

  const toggleModal = () => {
    setIsOpenModalFirma(!isOpenModalFirma);
  };

  const cerrarSesion = async () => {
    const logoutUrl = `${auth.settings.authority}connect/endsession?id_token_hint=${auth.user?.id_token}&post_logout_redirect_uri=${auth.settings.post_logout_redirect_uri}`;
    sessionStorage.clear();
    localStorage.clear();
    await new Promise((resolve) => {
      window.location.href = logoutUrl;
      resolve();
    });
    navigate("/");
  };
  return (
    <>
      <ModalFirma
        isOpenModalFirma={isOpenModalFirma}
        toggleModal={toggleModal}
      />
      <div className="flex justify-between h-20 my-auto px-2 border-b-4 border-b-[#00314D]  dark:bg-gray-700">
        <div className="my-auto lg:table-cell hidden">
          <button
            onClick={() => props.setOpenMenu(!props.OpenMenu)}
            type="button"
            className=" dark:text-white p-2.5 border-gray-200 text-gray-500  transition-all duration-500  "
          >
            {props.OpenMenu ? (
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 duration-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            )}
          </button>
        </div>
        <div className="my-auto lg:hidden">
          <button
            onClick={() => props.setOpenMobil(true)}
            type="button"
            className=" dark:text-white p-2.5 border-gray-200 text-gray-500  transition-all duration-500  "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div className="my-auto ">
          {props.loadingPermisos ? (
            <>
              <div className="mx-auto my-2 flex align-middle justify-center">
                <Oval
                  height={25}
                  width={25}
                  color="#FFFFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#233876"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            </>
          ) : (
            <>
              <SelectEmpresa />
            </>
          )}
        </div>
        <div className="my-auto z-50  ">
          <ul className="flex  items-center flex-shrink-0 space-x-2">
            <li
              data-bs-toggle="modal"
              data-bs-target="#centroModal"
              className="lg:table-cell md:table-cell hidden group relative"
            >
              <div className="h-10 w-10 bg-[#00314D] rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-white"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 0 0 6 21.75a6.721 6.721 0 0 0 3.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 0 1-.814 1.686.75.75 0 0 0 .44 1.223ZM8.25 10.875a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25ZM10.875 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875-1.125a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="absolute mx-auto w-24  -bottom-8 -left-8 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
                <p className="text-white dark:text-black  mx-auto text-sm">
                  Soporte
                </p>
              </div>
            </li>

            <li className="relative lg:block md:block hidden">
              <Menu as="div" className="relative group inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center dark:text-white  py-1.5 text-sm font-medium text-gray-700 ">
                    <div className="h-10 w-10 bg-[#00314D] rounded-full  flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6 text-white"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 6a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3V6ZM3 15.75a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-2.25Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3v-2.25Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>

                    <div className="absolute mx-auto w-24  -bottom-8 -left-8 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
                      <p className="text-white dark:text-black  mx-auto text-sm">
                        Aplicaciones
                      </p>
                    </div>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute  right-0 z-10 mt-2 w-64 origin-top-right rounded-md dark:bg-gray-700 dark:text-gray-400 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="mx-8 my-5 flex-col">
                      <p className="font-semibold">Aplicaciones</p>
                      <Menu.Item>
                        <button
                          onClick={() => setIsOpenModalFirma(true)}
                          className="flex space-x-2 items-center my-2"
                        >
                          <div className="bg-[#00314D] font-semibold h-8 w-8  flex justify-center items-center text-xl relative   text-white text-center">
                            <svg
                              className="fill-white w-5 h-5"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="w-56"
                                d="M12.0215 2.77165C12.9761 1.817 14.5239 1.817 15.4786 2.77166C16.4332 3.72631 16.4332 5.27411 15.4786 6.22876L14.2071 7.50023L14.2929 7.58603C15.0739 8.36708 15.0739 9.63341 14.2929 10.4145L12.8536 11.8538C12.6583 12.0491 12.3417 12.0491 12.1464 11.8538C11.9512 11.6585 11.9512 11.342 12.1464 11.1467L13.5858 9.70735C13.9763 9.31683 13.9763 8.68366 13.5858 8.29314L13.5 8.20734L6.85356 14.8538C6.78948 14.9178 6.70919 14.9633 6.62128 14.9853L2.62128 15.9853C2.44424 16.0295 2.25714 15.9738 2.13315 15.8399C2.00916 15.706 1.96796 15.5152 2.02567 15.3421L3.27567 11.5921C3.30022 11.5185 3.34157 11.4515 3.39646 11.3967L12.0215 2.77165ZM14.7715 3.47876C14.2073 2.91463 13.2927 2.91463 12.7286 3.47876L4.18703 12.0203L3.26238 14.7942L6.24439 14.0487L14.7715 5.52165C15.3356 4.95752 15.3356 4.04289 14.7715 3.47876Z"
                              />
                              <path d="M3.21723 16.8671C3.23411 16.8796 3.25117 16.8921 3.2684 16.9047C4.01112 17.4448 5.11223 18.0003 6.49999 18.0003C7.24299 18.0003 7.92358 17.7403 8.52866 17.3957C9.13152 17.0524 9.69205 16.6057 10.1903 16.2009L10.3073 16.1057C10.7711 15.7283 11.1686 15.4049 11.5351 15.1889C11.9383 14.9513 12.1792 14.9205 12.3419 14.9747C12.607 15.0631 12.7585 15.2675 13.021 16.1439C13.1084 16.4356 13.2643 16.7547 13.4924 17.0109C13.7209 17.2677 14.0609 17.5004 14.5 17.5004C14.9742 17.5004 15.4432 17.2722 15.8054 17.0586C16.044 16.9178 16.3005 16.7415 16.5084 16.5985C16.6106 16.5282 16.7012 16.4659 16.7721 16.4199C17.0401 16.2461 17.2659 16.1336 17.4205 16.0658C17.4977 16.032 17.557 16.0093 17.5948 15.9958C17.6136 15.989 17.6271 15.9846 17.6346 15.9821L17.6415 15.98C17.9053 15.9022 18.0569 15.6257 17.9802 15.3612C17.9034 15.096 17.6251 14.9436 17.3599 15.0205L17.3587 15.0208L17.3561 15.0216L17.3489 15.0237L17.3275 15.0305C17.3101 15.0361 17.2866 15.044 17.2575 15.0544C17.1993 15.0753 17.1184 15.1064 17.0189 15.15C16.82 15.2372 16.5458 15.3748 16.2279 15.5809C16.1107 15.6569 15.9986 15.7344 15.8856 15.8125C15.7007 15.9403 15.5131 16.07 15.2974 16.1972C14.9559 16.3986 14.6869 16.5004 14.5 16.5004C14.4391 16.5004 14.3501 16.4705 14.2393 16.346C14.1279 16.2208 14.0338 16.04 13.979 15.8569C13.739 15.0559 13.4795 14.2999 12.6581 14.0261C12.0707 13.8302 11.4992 14.0494 11.0274 14.3274C10.5919 14.584 10.1348 14.9564 9.68987 15.3188L9.5597 15.4248C9.05793 15.8324 8.55597 16.2294 8.03382 16.5267C7.5139 16.8228 7.007 17.0003 6.49999 17.0003C5.75399 17.0003 5.10397 16.8003 4.56599 16.5299L3.21723 16.8671ZM17.5 15.5004C17.3608 15.0202 17.36 15.0204 17.3599 15.0205L17.5 15.5004Z" />
                            </svg>
                          </div>
                          <div className="">Firma Electrónica</div>
                        </button>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href="https://taxflash-spa-ec-pro.azurewebsites.net/"
                          rel="noreferrer"
                          target="_blank"
                          className="flex space-x-2 items-center my-2"
                        >
                          <div className="bg-[#E30000] font-semibold h-8 w-8 flex justify-center items-center text-xl relative rounded-br-xl p-2 text-white text-center">
                            Tf
                          </div>
                          <div className="">TaxFlash</div>
                        </a>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>

            <li className="relative">
              <Menu as="div" className="relative group inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex  space-x-0 w-full justify-center dark:text-white  py-1.5 text-sm font-medium text-gray-700 ">
                    <div className="lg:h-10 md:h-10 lg:w-10 md:w-10 w-8 h-8 bg-[#00314D] rounded-full  flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6 text-white "
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 my-auto lg:hidden md:hidden"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                      />
                    </svg>
                    <div className="absolute mx-auto w-24  -bottom-8 -left-14 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
                      <p className="text-white dark:text-black  mx-auto text-sm">
                        Usuario
                      </p>
                    </div>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute  right-0 z-10 mt-2 w-64 origin-top-right rounded-md dark:bg-gray-700 dark:text-gray-400 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="relative ">
                      <div className="h-12  bg-[#00314D]"></div>
                      <div className="absolute flex   top-2 left-0 right-0 h-10 ">
                        {" "}
                        <p className="text-black bg-white font-semibold rounded-full text-2xl w-14 h-14 justify-center border  flex items-center mx-auto">
                          {usuario.nombre
                            .split(" ")
                            .map((user) => user.charAt(0))
                            .join("")
                            .slice(0, 2)}
                        </p>
                      </div>
                    </div>

                    <Menu.Item className="border-t w-full px-2 pt-9 flex items-center relative justify-end">
                      {({ active }) => (
                        <>
                          <p className="text-xs text-center justify-center items-center gap-x-2 flex font-semibold mt-5  pb-2">
                            {" "}
                            {usuario.nombre}
                            <NavLink to="/emision/reporte/editar-usuario">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-4 h-4 cursor-pointer text-gray-500"
                              >
                                <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                                <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                              </svg>
                            </NavLink>
                          </p>
                          <div className=" relative lg:hidden md:hidden">
                            <div className="flex justify-start items-center  mx-4 px-4 gap-x-1 ">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-settings"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                                  <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                </svg>
                              </div>
                              <p className="text-sm">Soporte</p>
                            </div>
                          </div>

                          <div className=" relative mt-2 lg:hidden md:hidden">
                            <div
                              className="flex justify-start items-center  mx-4 px-4 gap-x-1 "
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              <button>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M5 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                  <path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                  <path d="M19 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                  <path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                  <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                  <path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                  <path d="M5 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                  <path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                  <path d="M19 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                </svg>
                              </button>
                              <p className="text-sm">Aplicaciones</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </div>

                            {isOpen && (
                              <div className="mx-8 pl-5 my-5 flex-col">
                                <a
                                  target="_blank"
                                  href="https://accounts.anf.es/signin#"
                                  className="flex space-x-2 items-center my-2"
                                >
                                  <div className="bg-[#00314D] font-semibold h-7 w-7  flex justify-center items-center text-xl relative   text-white text-center">
                                    <svg
                                      className="fill-white w-4 h-4"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        className="w-56"
                                        d="M12.0215 2.77165C12.9761 1.817 14.5239 1.817 15.4786 2.77166C16.4332 3.72631 16.4332 5.27411 15.4786 6.22876L14.2071 7.50023L14.2929 7.58603C15.0739 8.36708 15.0739 9.63341 14.2929 10.4145L12.8536 11.8538C12.6583 12.0491 12.3417 12.0491 12.1464 11.8538C11.9512 11.6585 11.9512 11.342 12.1464 11.1467L13.5858 9.70735C13.9763 9.31683 13.9763 8.68366 13.5858 8.29314L13.5 8.20734L6.85356 14.8538C6.78948 14.9178 6.70919 14.9633 6.62128 14.9853L2.62128 15.9853C2.44424 16.0295 2.25714 15.9738 2.13315 15.8399C2.00916 15.706 1.96796 15.5152 2.02567 15.3421L3.27567 11.5921C3.30022 11.5185 3.34157 11.4515 3.39646 11.3967L12.0215 2.77165ZM14.7715 3.47876C14.2073 2.91463 13.2927 2.91463 12.7286 3.47876L4.18703 12.0203L3.26238 14.7942L6.24439 14.0487L14.7715 5.52165C15.3356 4.95752 15.3356 4.04289 14.7715 3.47876Z"
                                      />
                                      <path d="M3.21723 16.8671C3.23411 16.8796 3.25117 16.8921 3.2684 16.9047C4.01112 17.4448 5.11223 18.0003 6.49999 18.0003C7.24299 18.0003 7.92358 17.7403 8.52866 17.3957C9.13152 17.0524 9.69205 16.6057 10.1903 16.2009L10.3073 16.1057C10.7711 15.7283 11.1686 15.4049 11.5351 15.1889C11.9383 14.9513 12.1792 14.9205 12.3419 14.9747C12.607 15.0631 12.7585 15.2675 13.021 16.1439C13.1084 16.4356 13.2643 16.7547 13.4924 17.0109C13.7209 17.2677 14.0609 17.5004 14.5 17.5004C14.9742 17.5004 15.4432 17.2722 15.8054 17.0586C16.044 16.9178 16.3005 16.7415 16.5084 16.5985C16.6106 16.5282 16.7012 16.4659 16.7721 16.4199C17.0401 16.2461 17.2659 16.1336 17.4205 16.0658C17.4977 16.032 17.557 16.0093 17.5948 15.9958C17.6136 15.989 17.6271 15.9846 17.6346 15.9821L17.6415 15.98C17.9053 15.9022 18.0569 15.6257 17.9802 15.3612C17.9034 15.096 17.6251 14.9436 17.3599 15.0205L17.3587 15.0208L17.3561 15.0216L17.3489 15.0237L17.3275 15.0305C17.3101 15.0361 17.2866 15.044 17.2575 15.0544C17.1993 15.0753 17.1184 15.1064 17.0189 15.15C16.82 15.2372 16.5458 15.3748 16.2279 15.5809C16.1107 15.6569 15.9986 15.7344 15.8856 15.8125C15.7007 15.9403 15.5131 16.07 15.2974 16.1972C14.9559 16.3986 14.6869 16.5004 14.5 16.5004C14.4391 16.5004 14.3501 16.4705 14.2393 16.346C14.1279 16.2208 14.0338 16.04 13.979 15.8569C13.739 15.0559 13.4795 14.2999 12.6581 14.0261C12.0707 13.8302 11.4992 14.0494 11.0274 14.3274C10.5919 14.584 10.1348 14.9564 9.68987 15.3188L9.5597 15.4248C9.05793 15.8324 8.55597 16.2294 8.03382 16.5267C7.5139 16.8228 7.007 17.0003 6.49999 17.0003C5.75399 17.0003 5.10397 16.8003 4.56599 16.5299L3.21723 16.8671ZM17.5 15.5004C17.3608 15.0202 17.36 15.0204 17.3599 15.0205L17.5 15.5004Z" />
                                    </svg>
                                  </div>
                                  <div className="text-sm">
                                    Firma Electrónica
                                  </div>
                                </a>
                                <a
                                  href=" https://taxflash.intuitosa.com"
                                  target="_blank"
                                  className="flex space-x-2 items-center my-2"
                                >
                                  <div className="bg-[#E30000] font-semibold h-7 w-7 flex justify-center items-center text-xl relative rounded-br-xl p-2 text-white text-center">
                                    Tf
                                  </div>
                                  <div className="text-sm">TaxFlash</div>
                                </a>
                              </div>
                            )}
                          </div>

                          <div className=" mt-4 border-t ">
                            <button
                              onClick={() => cerrarSesion()}
                              data-bs-toggle="modal"
                              data-bs-target="#trazabilidadModal"
                              tabindex="-1"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700 dark:text-gray-400 ",
                                " px-4 py-2 text-xs w-full text-left group flex items-center justify-end"
                              )}
                            >
                              <svg
                                className="mr-1 h-5 w-5 "
                                aria-hidden="true"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
                              </svg>
                              <p className="text-smL">Cerrar Sesión</p>
                            </button>
                          </div>
                        </>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavBar;
