import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { post } from "../../../services";
import {
  getFechaInicio,
  getFechaV1,
  getfechaperiodofiscal,
  rellenarCeros,
  roundToTwo,
} from "../../../services/Utilitario";
import { TotalInicial } from "../../../features/bl/DocumentSharedBL";
import moment from "moment";
import { toast } from "react-toastify";
import { TextMessageCom } from "../../../features/components/TextMessageCom";
import { useHours } from "../../../features/hooks/useHours";

export const EmitirDocContext = createContext();

const EmitirDocProvider = (props) => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);
  const [saveData, setSaveData] = useState(false);
  const [saveDataMessage, setSaveDataMessage] = useState("");
  const [saveDataSvg, setSaveDataSvg] = useState(0);
  const [Totales, setTotales] = useState({ ...TotalInicial });
  const [ObjSaveData, setObjSaveData] = useState({});
  const [DataFromAdd, setDataFromAdd] = useState([]);
  const [DocSustentoList, setDocSustentoList] = useState([]);
  const [DataPagoModal, setDataPagoModal] = useState(null);

  /* Nuevo */
  const [groupedData, setGroupedData] = useState({});
  const [secuencialesExistentes, setSecuencialesExistentes] = useState([]);
  const [secuencialesProblemas, setSecuencialesProblemas] = useState([]);
  /* Nuevo */

  const [genxml, setgenxml] = useState(false);
  const [ActivarModalPago, setActivarModalPago] = useState(false);

  const [IsEdit, setIsEdit] = useState(false);
  const [EditElement, setEditElement] = useState({});
  const [DetalleList, setDetalleList] = useState([]);
  const [DestinatariosList, setDestinatariosList] = useState([]);
  const formik = useFormik({
    initialValues: {
      establecimiento: "",
      puntoEmision: "",
      secuencial: "",
      fechaEmision: getFechaInicio(),
      periodoFiscal: getfechaperiodofiscal(),
      codigoComprobanteModificar: "01",
      numeroComprobanteModificar: "",
      FechaEmisionDocSustento: getFechaInicio(),
      clienteIdentificacion: "",
      clienteRazonSocial: "",
      clienteDireccion: "",
      clienteEmail: "",
      clienteTelefono: "",
      codigo: "",
      NumAutDocSustento: "",
      idContribuyente: "",
      IsPartRel: ""
    },
    validationSchema: Yup.object({
      secuencial: Yup.string().max(9, "Tamaño del campo 9"),
      clienteIdentificacion: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      clienteRazonSocial: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      clienteDireccion: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      clienteEmail: Yup.string().required("Este campo es requerido").max(300),
    }),
    onSubmit: async (obj) => {
      const fechaEmision = moment(formik.values.fechaEmision, "YYYY-MM-DD");
      const fechaLimite = moment().subtract(3, "months");
      if (fechaEmision.isAfter(fechaLimite)) {
        console.log(
          "La fecha de emisión es válida y está dentro de los últimos 3 meses."
        );

      } else {
        toast.error(
          "La fecha de emisión no es válida, no está dentro de los últimos 3 meses."
        );

        throw new Error("Error de validacion");
      }


      if (formik.values.fechaEmision > moment().format('YYYY-MM-DD')) {
        toast.error("La fecha de inicio no puede ser mayor a la fecha actual");
        return;
      }
      setSaveDataSvg(0);
      if (isOver72Hours) {
        setSaveDataMessage(<TextMessageCom />);
      } else {
        setSaveDataMessage("¿ESTÁ SEGURO DE QUE DESEA ENVIAR AL SRI?");
      }
      setSaveData(true);
      //console.log('---------------------')
      //console.log(obj)
      setObjSaveData(obj);
    },
  });

  const guardarDocumento = async () => {

    setSaveDataSvg(1);
    setSaveDataMessage("PROCESANDO...");
    try {
      const documento = {
        IdentificacionContribuyente: ObjSaveData.clienteIdentificacion,
        RazonSocialContribuyente: ObjSaveData.clienteRazonSocial,
        Direccion: ObjSaveData.clienteDireccion,
        Correo: ObjSaveData.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
        TelefonoCelular: ObjSaveData.clienteTelefono,
        CodigoComprobanteModificar: ObjSaveData.codigoComprobanteModificar,
        IdContribuyente: ObjSaveData.idContribuyente,
        IsPartRel: ObjSaveData.IsPartRel === "1" ? true : false,
        Ambiente: process.env.REACT_APP_ENVIRONMENT,
        IdEmpresa: empresa.idEmpresa,
        FechaEmision: getFechaV1(ObjSaveData.fechaEmision),
        PeriodoFiscal: ObjSaveData.periodoFiscal,
        ImporteTotal: roundToTwo(Totales.importeTotal),
        TotalSinImpuestos: Totales.totalSinImpuesto,
        TotalDescuento: Totales.totalDescuentos,
        DocsSustento: DataFromAdd,
        infoAdicional: InformacionAdicionalList.map((x) => {
          return {
            Descripcion: x.nombre,
            Valor: x.valor,
          };
        }),
        IdImpuestoNoObjectoImpuesto:
          Totales.subTotalNoObjetoIva !== 0
            ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
            : "",
        IdImpuestoExentoIva:
          Totales.subTotalExentoIva !== 0
            ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
            : "",
        IdImpuestoIVA12:
          Totales.subTotalIva !== 0
            ? "A5B8F607-8D38-4150-97AF-C591F83678D8"
            : "",
        IdImpuestoIVA0:
          Totales.subTotalIva0 !== 0
            ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
            : "",
        TotalTotalIva0: 0,
        TotalTotalIva: Totales.totalIva,
        TotalNoObjetoImpuesto: 0,
        TotalExentoIva: 0,
        TotalBaseIva0: Totales.subTotalIva0,
        TotalBaseIva: Totales.subTotalIva,
        TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
        TotalBaseExentoIva: Totales.subTotalExentoIva,
        Establecimiento: ObjSaveData.establecimiento,
        PuntoEmision: ObjSaveData.puntoEmision,
      };
      if (ObjSaveData.clienteEmail !== null)
        if (ObjSaveData.clienteEmail !== "")
          documento.infoAdicional.push({
            Valor: ObjSaveData.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
            Descripcion: "email",
          });

      documento.DocsSustento.forEach((el) => {
        el.NumDocSustento = el.NumDocSustento.replaceAll("-", "");
      });

      const Establecimiento = ObjSaveData.establecimiento;
      const PuntoEmision = ObjSaveData.puntoEmision;
      const Secuencial = ObjSaveData.secuencial;
      // console.log(JSON.stringify(documento));

      await post("/api/Retenciones/GuardarRetencion", documento);
      formik.setFieldValue("establecimiento", Establecimiento);
      formik.setFieldValue("puntoEmision", PuntoEmision);
      formik.setFieldValue("secuencial", rellenarCeros(Secuencial, 9));

      resetForm();
      setSaveDataSvg(2);
      setSaveDataMessage("EL DOCUMENTO SE GENERO EXITOSAMENTE");
      setgenxml(true);
      // }
    } catch (error) {
      //  console.log(error);
      setSaveDataSvg(-1);
      setSaveDataMessage("ERROR AL GENERAR EL DOCUMENTO");
      setgenxml(false);
    }
  };
  const [isOpenModalHours, setisOpenModalHours] = useState(false);

  const toggleModal = () => {
    setisOpenModalHours(!isOpenModalHours);
  };

  const { isOver72Hours } = useHours({
    fechaActual: getFechaInicio(),
    FechaInput: formik.values.fechaEmision,
    toggleModal,
  });
  const resetForm = () => {
    const Establecimiento = ObjSaveData.establecimiento;
    const PuntoEmision = ObjSaveData.puntoEmision;
    const Secuencial = ObjSaveData.secuencial;

    formik.resetForm();
    setInformacionAdicionalList([]);
    formik.setFieldValue("establecimiento", Establecimiento);
    formik.setFieldValue("puntoEmision", PuntoEmision);
    formik.setFieldValue("secuencial", rellenarCeros(Secuencial, 9));
    setTotales({ ...TotalInicial });

    setDataFromAdd([]);
  };

  return (
    <EmitirDocContext.Provider
      value={{
        isOpenModalHours,
        setisOpenModalHours,
        toggleModal,
        saveData,
        setSaveData,
        saveDataMessage,
        saveDataSvg,
        guardarDocumento,
        formik,
        Totales,
        InformacionAdicionalList,
        setInformacionAdicionalList,
        DataFromAdd,
        setDataFromAdd,
        ActivarModalPago,
        setActivarModalPago,
        DocSustentoList,
        setDocSustentoList,
        DataPagoModal,
        setDataPagoModal,
        IsEdit,
        setIsEdit,
        EditElement,
        setEditElement,
        resetForm,
        genxml,
        setgenxml,
        DetalleList,
        DestinatariosList,
        /* Carga Masiva */
        groupedData, setGroupedData,
        secuencialesExistentes, setSecuencialesExistentes,
        secuencialesProblemas, setSecuencialesProblemas
      }}
    >
      {props.children}
    </EmitirDocContext.Provider>
  );
};

export default EmitirDocProvider;
