import React, { useState } from 'react'
import { useLocation } from 'react-router';
import InfoEmpresa from './InfoEmpresa';
import AdministradoresList from './AdministradoresList';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const EditEmpresaList = () => {

  let values = useQuery();

  //esta variable se usa para poder tener una lista de usuarios para que luego de crear la empresa, se puedan crear los administradores
  const [ListAdminsForNewEmpresa, setListAdminsForNewEmpresa] = useState([]);

  return (
    <div className="space-y-6">
        <div className="  dark:bg-gray-900 sm:rounded-lg p-5 pt-3">
          <InfoEmpresa ListAdminsForNewEmpresa={ListAdminsForNewEmpresa} idEmpresa={values.get("id")} />
          {/* <AdministradoresList ListAdminsForNewEmpresa={ListAdminsForNewEmpresa} setListAdminsForNewEmpresa={setListAdminsForNewEmpresa} idEmpresa={values.get("id")} /> */}
        </div>
    </div>
  )
}

export default EditEmpresaList;