import React, { useContext, useEffect, useState } from "react";
import MesageError from "../../../features/components/MessageError";
import { useSelector } from "react-redux";
import {
  StyleInput,
  StyleSelect,
  StyleInputError,
} from "../../../features/Constantes";

import { getListV2 } from "../../../services";
import { EmitirGuiaRemisionContext } from "./EmitirGuiaRemisionProvider";
import {
  getFechaV1,
  getNoventaDiasAtras,
  rellenarCeros,
} from "../../../services/Utilitario";
import DestinatarioForm from "./DestinatarioForm";
import useVistaPrevia from "../hooks/useVistaPrevia";
import MobileButtonOptions from "./MobileButtonOptions";
import { toast } from "react-toastify";
import InputText from "../../../features/components/common/InputText";
import MobileSubmitButton from "./MobileButtonOptions";
import { Transition } from "@headlessui/react";
import Toggle from "../../../features/components/Toggle";
import { ModalHours } from "../../../features/hooks/ModalHours";

const FormFactura = () => {
  const {
    formik,
    DestinatariosList,
    DetalleList,
    InformacionAdicionalList,
    handleResetDocument,
    isOpenModalHours, setisOpenModalHours,
    toggleModal
  } = useContext(EmitirGuiaRemisionContext);

  const empresa = useSelector((store) => store.empresa.empresa);

  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);
  const [BuscarCliente, setBuscarCliente] = useState(false);
  const [BuscarClienteIndetificacion, setBuscarClienteIndetificacion] =
    useState(false);
  const [
    BuscarClienteIndetificacionError,
    setBuscarClienteIndetificacionError,
  ] = useState(false);
  const [BuscarClienteRazonSocial, setBuscarClienteRazonSocial] = useState([]);
  const [BuscarClienteRazonSocialText, setBuscarClienteRazonSocialText] =
    useState("");

  const [MinFechaFin, setMinFechaFin] = useState(getNoventaDiasAtras());

  const [estabTmp, setEstabTmp] = useState("###");
  const [pteTmp, setPteTmp] = useState("###");
  const [secTmp, setSecTmp] = useState("#########");

  // console.log("sec",secTmp)

  const { GetVistaPreviaGuiaRemision, IsLoading } = useVistaPrevia();

  useEffect(() => {
    (async () => {
      //ObtenerEstablecimientosPorEmpresa
      const GetAllEstablecimientos = await getListV2(
        empresa,
        "ObtenerEstablecimientosPorEmpresa"
      );
      formik.setFieldValue(
        "establecimiento",
        GetAllEstablecimientos._embedded[0].codigo
      );
      formik.setFieldValue(
        "puntoPartidaComprobante",
        GetAllEstablecimientos._embedded[0].direccion
      );
      setEstabTmp(GetAllEstablecimientos._embedded[0].codigo);
      setEstablecimientos(GetAllEstablecimientos._embedded);

      await getPuntosEmision(GetAllEstablecimientos._embedded[0]);
    })();
  }, []);

  const handleChangeEstablecimiento = async (e) => {
    if (e.target.value) {
      const index = e.target.selectedOptions[0].getAttribute("data-index");

      formik.setFieldValue("puntoEmision", "");
      formik.setFieldValue("secuencial", "");
      setEstabTmp(e.target.value);
      const establecimiento = Establecimientos[index];

      formik.setFieldValue(
        "puntoPartidaComprobante",
        establecimiento.direccion
      );

      await getPuntosEmision(establecimiento);

      formik.handleChange(e);
    }
  };

  const handleVistaPrevia = async () => {
    try {
      let parsedInfo = {
        IdEmpresa: empresa.idEmpresa,
        RucEmpresa: empresa.ruc,
        Establecimiento: formik.values.establecimiento,
        PuntoEmision: formik.values.puntoEmision,
        Secuencial: formik.values.secuencial,
        Ambiente: process.env.REACT_APP_ENVIRONMENT,
        NumAutDocSustento: formik.values.numAutDocSustento,
        FechaEmision: formik.values.fechaInicio,
        FechaInicioTransporte: formik.values.fechaInicio,
        FechaFinTransporte: formik.values.fechaFin,
        TipoIdentificacionTransportista:
          formik.values.tipoIdentificacionTransportista,
        RucTransportista: formik.values.transportistaIdentificacion,
        RazonSocialTransportista: formik.values.transportistaRazonSocial,
        CorreoTransportista: formik.values.transportistaEmail
          .replace(/,/g, ";")
          .replace(/\s+/g, ""),
        IdTransportista: formik.values.IdTransportista,
        Placa: formik.values.placa,
        DirPartida: formik.values.puntoPartidaComprobante,
        destinatario: [...DestinatariosList],
        infoAdicional: InformacionAdicionalList.map((x) => {
          return {
            Nombre: x.nombre,
            Valor: x.valor,
          };
        }),
      };

      if (DestinatariosList.length === 0) {
        toast.error("Falta Destinatario");
        return;
      }

      // if (DestinatariosList[0].detalles.length === 0) {
      //   toast.info("Debe agregar un detalle");
      //   return;
      // }

      if (formik.values.transportistaEmail !== null)
        if (formik.values.transportistaEmail.trim() !== "")
          parsedInfo.infoAdicional.push({
            Valor: formik.values.transportistaEmail
              .replace(/,/g, ";")
              .replace(/\s+/g, ""),
            Nombre: "email",
          });

      let data = await GetVistaPreviaGuiaRemision(parsedInfo);

      if (data.includes("System.NullReferenceException")) {
        toast.error("Hubo un error al obtener la vista previa.");
        return;
      }

      if (data === "") {
        toast.error("Faltan datos por completar");
        return;
      }

      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: "application/pdf;base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (erro) {
      //console.log(erro);
    }
  };

  const getPuntosEmision = async (establecimiento) => {
    const GetAllPuntosEmision = await getListV2(
      establecimiento,
      "GetListPuntosEmision",
      `?Estado=1`
    );

    setSecTmp("#########");
    if (GetAllPuntosEmision._embedded.length > 0) {
      setPteTmp(GetAllPuntosEmision._embedded[0].codigo);
      setPuntosEmision(GetAllPuntosEmision._embedded);
      formik.setFieldValue(
        "puntoEmision",
        GetAllPuntosEmision._embedded[0].codigo
      );
      await getSecuencial(GetAllPuntosEmision._embedded[0]);
    } else {
      setPteTmp("###");
      formik.setFieldValue("puntoEmision", "");
      setPuntosEmision([]);
    }
  };

  const getSecuencial = async (puntoEmisionObj) => {
    try {
     

      const GetAllPuntosEmision = await getListV2(
        puntoEmisionObj,
        "getSecuencia-GuiaRemision"
      );
    

      setSecTmp(GetAllPuntosEmision._embedded.codigo);
      formik.setFieldValue("secuencial", GetAllPuntosEmision._embedded.codigo);
    } catch (error) {
      //   console.log(error);
      setSecTmp("#########");
      formik.setFieldValue("secuencial", "");
    }
  };

  const handleChangePuntoEmision = (e) => {
    setSecTmp("#########");
    if (e.target.value) {
      setPteTmp(e.target.value);

      const index = e.target.selectedOptions[0].getAttribute("data-index");

      const puntoEmision = PuntosEmision[index];

      // console.log(`${puntoEmision.idPuntoEmision}`);

      getSecuencial(puntoEmision);

      formik.handleChange(e);
    }
  };

  const handleOnclick = (e) => {
    // solo se muestra mientras no se este buscando por cedula
    if (!BuscarClienteIndetificacion) {
      setBuscarCliente(!BuscarCliente);
    }
  };

  const handleOnBlurCliente = async (value) => {
    setBuscarCliente(false);

    if (value) {
      if (value.length > 5) {
        setBuscarClienteIndetificacionError(false);

        setBuscarClienteIndetificacion(true);
        // agregar el codigo de buscar por cedula en la tabla contribuyentes
        try {
          let identificacion =
            value.length === 13 ? value.substr(0, 10) : value;

          const data = await getListV2(
            empresa,
            "GetContribuyente",
            `?Identificacion=${identificacion}&IsTransportista=${true}&Cantidad=1&Pagina=1&Activo=${true}`
          );
          const valores = data._embedded[0];
          // console.log(data);
          // console.log(valores);
          formik.setFieldValue(
            "transportistaIdentificacion",
            valores.esRuc
              ? valores.identificacion + "001"
              : valores.identificacion
          );
          formik.setFieldValue("IdTransportista", valores.idContribuyente);
          formik.setFieldValue("transportistaRazonSocial", valores.razonSocial);
          formik.setFieldValue(
            "tipoIdentificacionTransportista",
            valores.idTipoIdentificacionNavigation.codigo
          );
          formik.setFieldValue("transportistaDireccion", valores.direccion);
          formik.setFieldValue("transportistaEmail", valores.correo);
          formik.setFieldValue(
            "transportistaTelefono",
            valores.telefonoCelular
          );
          formik.setFieldValue("placa", valores.placa);
        } catch (err) {
          // console.log(err);
          formik.setFieldValue("transportistaRazonSocial", "");
          formik.setFieldValue("transportistaDireccion", "");
          formik.setFieldValue("transportistaEmail", "");
          formik.setFieldValue("transportistaTelefono", "");
          formik.setFieldValue("placa", "");
        }

        //formik.handleChange(e);

        setBuscarClienteIndetificacion(false);
      } else {
        setBuscarClienteIndetificacionError(true);
      }
    } else {
      setBuscarClienteIndetificacionError(false);
      formik.setFieldValue("transportistaIdentificacion", "");
    }
  };

  const handleOnClickCliente = (cliente, index) => {
    setBuscarClienteIndetificacionError(false);
    setBuscarClienteIndetificacion(false);
    setBuscarCliente(false);
    formik.setFieldValue("clienteIdentificacion", cliente.identificacion);
    handleOnBlurCliente(cliente.identificacion);
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => handleSearchRazonSocial(BuscarClienteRazonSocialText),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [BuscarClienteRazonSocialText]);

  const handleSearchRazonSocial = async (param) => {
    if (param) {
      if (param.trim() !== "") {
        if (param.trim().length > 2) {
          // console.log("uuuuuuuuuuuuuuu");
          const data = await getListV2(
            empresa,
            "GetContribuyenteByRazonSocial",
            `?razonSocial=${param.trim()}&empresaId=${
              empresa.idEmpresa
            }&IsTransportista=1&isActive=1`
          );
          setBuscarClienteRazonSocial(data._embedded);
          // console.log(data._embedded);
        }
      }
    }
  };

  const handleClickSubmitFactura = async (e, data) => {
    if (DestinatariosList.length <= 0) {
      toast.error("Faltan campos por llenar en destinatarios");
      e.preventDefault();
      return;
    }

    await formik.handleSubmit(() => {
      setSecTmp((prevSecTmp) => prevSecTmp + 1);
      formik.setFieldValue("secuencial", secTmp + 1);
    });
  };
  const [isOpen, setIsOpen] = useState(true);
  const [valueOptionsHeader, setValueOptionsHeader] = useState("FACTURA");


  
  
  
  return (
    <form onSubmit={formik.handleSubmit}>
        <ModalHours isOpenModalHours={isOpenModalHours} toggleModal={toggleModal} />

      {IsLoading && (
        <div
          style={{ width: "100vw", height: "100vh", zIndex: "100" }}
          className="bg-opacity-70 fixed justify-center align-middle top-0 l-0 bg-white text-center"
        >
          <div className="w-full h-full flex justify-center items-center py-4">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      <div className="border dark:border-gray-600 rounded-xl bg-white dark:bg-gray-900 ">
        <div className="flex justify-between">
          {/* <div className="flex lg:w-[26rem] md:w-96 w-full ">
            <div
              onClick={() => setValueOptionsHeader("GUIA")}
              className={
                valueOptionsHeader === "GUIA" ? ClassAvite : ClassInactive
              }
            >
              {" "}
              <p className="lg:text-2xl md:text-2xl">Guía de Remisión</p>
            </div>
            <div
              onClick={() => setValueOptionsHeader("OTROS")}
              className={
                valueOptionsHeader === "OTROS"
                  ? ClassActive2 
                  : ClassInactive2 
              }
            >
              <p>  Más opciones</p>
             
              <MobileSubmitButton />
            </div>
          </div> */}


          <Toggle text="Guía de Remisión" setValueOptionsHeader={setValueOptionsHeader}>
            <MobileButtonOptions />
          </Toggle>
          <div className=" lg:flex md:flex sm:hidden mx-2  ">
            <div className=" group relative inline-block">
              <div className=" cursor-pointer w-10 h-10 rounded-full  inline-flex items-center justify-center">
                <button
                  type="button"
                  onClick={handleResetDocument}
                  className="inline-flex h-10 w-10 cursor-pointer  hover:shadow-md items-center justify-center rounded-xl  transition dark:bg-gray-900 dark:hover:bg-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6 dark:text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>

                  <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                    <p className="text-white dark:text-black mx-auto text-sm ">
                      Limpiar
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2">
          {valueOptionsHeader === "FACTURA" ? (
            <>
              <div className="bg-[#F7F7F7] dark:bg-gray-700 p-2  rounded-lg">
                <div className=" grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2 w-full max-w-2xl text-center">
                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Establecimiento:{" "}
                    </label>
                    <select
                      onChange={handleChangeEstablecimiento}
                      type="text"
                      name="establecimiento"
                      id="establecimiento"
                      className={StyleSelect}
                      placeholder=" "
                      defaultValue={formik.values.establecimiento}
                      onBlur={formik.handleBlur}
                    >
                      {/*data-id-establecimiento={el.idEstablecimiento}*/}
                      {Establecimientos.map((el, index) => {
                        return (
                          <option
                            key={el.idEstablecimiento}
                            data-index={index}
                            value={el.codigo}
                          >
                            {rellenarCeros(el.codigo)} {/*- {el.descripcion}*/}
                          </option>
                        );
                      })}
                    </select>
                    {/* <label htmlFor="establecimiento" className={StyleLabelForm}>
                    
                    Establecimiento
                  </label> */}
                    {formik.touched.establecimiento &&
                    formik.errors.establecimiento ? (
                      <MesageError message={formik.errors.establecimiento} />
                    ) : null}
                  </div>
                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Punto de emisión:{" "}
                    </label>
                    <select
                      type="text"
                      className={StyleSelect}
                      placeholder=" "
                      name="puntoEmision"
                      id="puntoEmision"
                      defaultValue={formik.values.puntoEmision}
                      onChange={handleChangePuntoEmision}
                      onBlur={formik.handleBlur}
                    >
                      {PuntosEmision.map((el, index) => {
                        return (
                          <option
                            key={el.idPuntoEmision}
                            value={el.codigo}
                            data-index={index}
                          >
                            {rellenarCeros(el.codigo)} {/*- {el.descripcion}*/}
                          </option>
                        );
                      })}
                    </select>
                    {/* <label htmlFor="puntoEmision" className={StyleLabelForm}>
                    
                    Punto Emisión
                  </label> */}
                    {formik.touched.puntoEmision &&
                    formik.errors.puntoEmision ? (
                      <MesageError message={formik.errors.puntoEmision} />
                    ) : null}
                  </div>
                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      Secuencial:
                    </label>
                    <input
                      type="text"
                       name="secuencial"
                      id="secuencial"
                      disabled={true}
                      className={StyleSelect + " bg-[#EBEBEB]"}
                      value={rellenarCeros(formik.values.secuencial || "0", 9)}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="bg-[#F7F7F7] dark:bg-gray-700 p-2  rounded-lg">
                <div className=" grid  lg:grid-cols-4 md:grid-cols-2  gap-2 w-full max-w-2xl  text-center">
                  <div className="relative z-0  w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium  text-left text-black dark:text-white"
                    >
                      {" "}
                      Fecha inicio transporte:{" "}
                    </label>
                    <input
                      type="date"
                      className={StyleInput}
                      name="fechaInicio"
                      id="fechaInicio"
                      min={getNoventaDiasAtras()}
                      value={formik.values.fechaInicio}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setMinFechaFin(e.target.value);
                        // console.log(
                        //   new Date(formik.values.fechaFin) <
                        //     new Date(e.target.value)
                        // );
                        if (
                          new Date(formik.values.fechaFin) <
                          new Date(e.target.value)
                        ) {
                          formik.setFieldValue("fechaFin", e.target.value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      placeholder=" "
                    />
                  </div>

                  <div className="lg:col-span-2 relative z-0   w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-left text-black dark:text-white"
                    >
                      {" "}
                      Punto Partida:{" "}
                    </label>
                    <input
                      type="text"
                      className={StyleInput + " resize-none"}
                      placeholder=" "
                      id="puntoPartidaComprobante"
                      name="puntoPartidaComprobante"
                      value={formik.values.puntoPartidaComprobante}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength="300"
                    />
                  </div>

                  <div className="relative z-0  w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black  text-left dark:text-white"
                    >
                      {" "}
                      Fecha fin transporte:{" "}
                    </label>
                    <input
                      type="date"
                      className={StyleInput}
                      StyleSelectForm
                      name="fechaFin"
                      id="fechaFin"
                      min={MinFechaFin}
                      value={formik.values.fechaFin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder=" "
                    />
                    {/* <label htmlFor="fechaFin" className={StyleLabelForm}>
              Fecha Fin Transporte
              </label> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="bg-white dark:bg-gray-900 lg:p-2 px-1 lg:px-4 md:px-0 lg:pt-4 md:pt-0 pt-4 md:p-2 rounded-t-lg mt-2">
      <div className="border dark:border-gray-600 rounded-lg  mb-4">
          <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white flex justify-between">
            <h3>Transportista</h3>
            <svg
              onClick={() => setIsOpen(!isOpen)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`w-4 h-4 my-auto lg:hidden md:hidden duration-150 ${isOpen ? "transform rotate-180" : ""} dark:text-white`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
          
            <Transition
              show={isOpen}
              enter="transition ease-out duration-300" // Aumenta la duración de entrada
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-300" // Aumenta la duración de salida
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              
              <div className="w-full grid md:grid-cols-4 md:gap-2 mb-2 p-4 ">
                <div className="relative  w-full group">
                  <label className="block text-sm font-medium text-black dark:text-white">
                    {" "}
                    Identificación: *{" "}
                  </label>
                  <input
                    type="text"
                    className={
                      (formik.touched.transportistaIdentificacion &&
                        formik.errors.transportistaIdentificacion) ||
                      BuscarClienteIndetificacionError
                        ? StyleInputError
                        : StyleInput
                    }
                    placeholder="Digite número de Identificación"
                    name="transportistaIdentificacion"
                    id="transportistaIdentificacion"
                    value={formik.values.transportistaIdentificacion}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trim();
                      formik.setFieldValue("transportistaIdentificacion", trimmedValue);
                    }}
                    onBlur={(e) => handleOnBlurCliente(e.target.value)}
                    maxLength="20"
                  />

                  {/* {formik.touched.transportistaIdentificacion &&
              formik.errors.transportistaIdentificacion ? (
                <MesageError
                  message={formik.errors.transportistaIdentificacion}
                />
              ) : null} */}
                  <button
                    onClick={handleOnclick}
                    type="button"
                    id="dropdownSearchButton"
                    data-dropdown-toggle="dropdownSearch"
                    data-dropdown-placement="bottom"
                    className="absolute inset-y-0 right-0  items-center pl-3 text-sm text-gray-900 pt-4  border border-r-0 border-t-0 border-l-0 border-b-0 border-gray-300  dark:text-gray-400 dark:border-gray-600"
                  >
                    {BuscarClienteIndetificacion ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-3 w-4 h-4 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="inline mr-2 -ml-1 w-5 h-5 dark:stroke-white dark:hover:stroke-blue-600  "
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </>
                    )}
                  </button>
                  <div
                    id="dropdownSearch"
                    className={`${
                      BuscarCliente ? "block" : "hidden"
                    }  bg-white rounded shadow dark:bg-gray-700 absolute w-full`}
                    style={{ zIndex: "15" }}
                  >
                    <div className="p-3">
                      <label htmlFor="input-group-search" className="sr-only">
                        Buscar
                      </label>
                      <div className="relative">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                          <svg
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="razonSocialSearch"
                          name="razonSocialSearch"
                          value={BuscarClienteRazonSocialText}
                          onChange={(e) =>
                            setBuscarClienteRazonSocialText(e.target.value)
                          }
                          onBlur={(e) =>
                            setBuscarClienteRazonSocialText(e.target.value)
                          }
                          className="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Digite Razón Social / Nombre"
                        />
                      </div>
                    </div>
                    <ul
                      className="overflow-y-auto px-3 pb-3 h-48 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownSearchButton"
                    >
                      {BuscarClienteRazonSocial.length === 0 ? (
                        <li key="01">
                          <div className="text-sm mt-1 text-yellow-400 dark:text-yellow-400 inline-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <div style={{ position: "relative" }}>
                              <span className="font-semibold">
                                No se encontraron datos
                              </span>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {BuscarClienteRazonSocial.map((el, index) => (
                        <li key={index}>
                          <div
                            onClick={() =>
                              handleOnClickCliente(
                                { identificacion: el.identificacion },
                                1
                              )
                            }
                            className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                              />
                            </svg>
                            <label
                              htmlFor="checkbox-item-11"
                              className="py-2 ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                            >
                              {el.identificacion} - {el.razonSocial}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="flex items-center p-3 text-sm font-medium text-red-600 bg-gray-50 border-t border-gray-200 dark:border-gray-600  dark:bg-gray-700  dark:text-red-500 ">
                      {/*<svg className="mr-1 w-5 h-5" ariaHidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"></path></svg>
Delete user*/}
                    </div>
                  </div>
                </div>
                <div className="relative z-0 w-full group">
                  <label
                    htmlFor="company-website"
                    className="block text-sm font-medium text-black dark:text-white"
                  >
                    {" "}
                    Razón social: *{" "}
                  </label>
                  <input
                    type="text"
                    className={
                      formik.touched.transportistaRazonSocial &&
                      formik.errors.transportistaRazonSocial
                        ? StyleInputError
                        : StyleInput
                    }
                    placeholder=" "
                    id="transportistaRazonSocial"
                    name="transportistaRazonSocial"
                    value={formik.values.transportistaRazonSocial}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength="300"
                  />
                </div>
                <div className="relative z-0 w-full group">
                  <label className="block text-sm font-medium text-black dark:text-white">
                    {" "}
                    Placa: *{" "}
                  </label>
                  <input
                    type="text"
                    className={
                      formik.touched.placa && formik.errors.placa
                        ? StyleInputError
                        : StyleInput
                    }
                    placeholder=" "
                    id="placa"
                    name="placa"
                    value={formik.values.placa}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength="20"
                  />
                </div>
                <div className="relative z-0 w-full group">
                  <label className="block text-sm font-medium text-black dark:text-white">
                    {" "}
                    Correo: *{" "}
                  </label>
                  <input
                    type="text"
                    className={
                      formik.touched.transportistaEmail &&
                      formik.errors.transportistaEmail
                        ? StyleInputError
                        : StyleInput
                    }
                    placeholder=" "
                    id="transportistaEmail"
                    name="transportistaEmail"
                    value={formik.values.transportistaEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength="300"
                  />
                </div>
              </div> 
            </Transition>
       
        </div>
                    
        <DestinatarioForm />
      </div>
    </form>
  );
};

export default FormFactura;
