import axios from "axios";
import { MailsRegExp } from "../../../../services/Utilitario";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import UserExisteIcon from "./Icons/userExisteIcon";
import UserErrorIcon from "./Icons/userErrorIcon";

export default function validateSolicitud() {
  const validate = async (
    TotalValues,
    methods,
    valueTransportista,
    empresa,
    setMessageSubmit,
    setMessageDescripcionSubmit,
    setsvgSubmit
  ) => {
    try {
      setMessageSubmit("Validando datos...");
      setMessageDescripcionSubmit("Por favor espere un momento.");
      TotalValues.identificacion = TotalValues.identificacion.trim();
      TotalValues.razonsocial = TotalValues.razonsocial.trim();
      TotalValues.correo = TotalValues.correo.trim();
      TotalValues.telefonocelular = TotalValues.telefonocelular
        ? TotalValues.telefonocelular.replaceAll("-", "")
        : TotalValues.telefonocelular;
      TotalValues.telefonoconvencional = TotalValues.telefonoconvencional
        ? TotalValues.telefonoconvencional.replaceAll("-", "")
        : TotalValues.telefonoconvencional;
      TotalValues.IsTransportista = valueTransportista === "1";
      TotalValues.IsPartRel = TotalValues.IsPartRel === "1";
      TotalValues.activo = true;

      if (TotalValues.identificacion === "") {
        return setMessageSubmit("El campo identificación es obligatorio!");
      }
      if (TotalValues.razonsocial === "") {
        return toast.warning("El campo razón social es obligatorio!", {
          isLoading: false,
          autoClose: 2000,
        });
      }
      if (TotalValues.direccion.trim() === "") {
        return toast.warning("El campo direccion es obligatorio!", {
          isLoading: false,
          autoClose: 2000,
        });
      }
      var req = await searchContribuyente(TotalValues, empresa, setMessageSubmit,setMessageDescripcionSubmit,setsvgSubmit);
      if (req === "existe") throw new Error("El contribuyente ya existe");
      if (TotalValues.EsRuc === "1") {
      } else if (
        TotalValues.EsCedula === "1" &&
        TotalValues.identificacion.length === 10
      ) {
        TotalValues.identificacion = TotalValues.identificacion + "001";
      }

      if (TotalValues.EsRuc || TotalValues.EsCedula) {
        delete TotalValues.EsRuc;
        delete TotalValues.EsCedula;
      }

      if (TotalValues.correo === "") {
        const emails = TotalValues.correo.split(";");
        for (let i = 0; i < emails.length; i++) {
          const email = emails[i].trim();
          if (!MailsRegExp.test(email)) {
            //   return toast.warning("El correo ingresado no es válido!", {
            //     isLoading: false,
            //     autoClose: 2000,
            //   });
            return setMessageSubmit("El correo ingresado no es válido!");
          }
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const searchContribuyente = async (
    TotalValues,
    empresa,
    setMessageSubmit,
    setMessageDescripcionSubmit,
    setsvgSubmit
  ) => {
    try {
      if (TotalValues.identificacion.length > 10) {
        const identificacionSinUltimosTres = TotalValues.identificacion.replace(
          /001$/,
          ""
        );
        const req = await axios.get(
          `api/contribuyente/existe/${empresa.idEmpresa}?identificacion=${identificacionSinUltimosTres}`
        );

        if(req.data === true){
          setMessageSubmit(
            "El número de identificación ya existe en la base de datos!"
          );
          setMessageDescripcionSubmit("Por favor verifique los datos ingresados.");
          setsvgSubmit(<UserExisteIcon />);
          return "existe";

        }
      } else {
        const req = await axios.get(
          `api/contribuyente/existe/${empresa.idEmpresa}?identificacion=${TotalValues.identificacion}`
        );

        if(req.data === true){
          setMessageSubmit(
            "El número de identificación ya existe en la base de datos!"
          );

          setMessageDescripcionSubmit("Por favor verifique los datos ingresados.");
          setsvgSubmit(<UserExisteIcon />);
          return "existe"; 

        }


      }
    } catch (error) {
      setMessageSubmit("Ocurrio un error...");
      setMessageDescripcionSubmit(
        "No se ha podido agregar el contribuyente. Por favor, verifique su conexión a internet, asegúrese de que los datos sean correctos y actualice el portal para intentarlo nuevamente"
      );
      setsvgSubmit(<UserErrorIcon />);
      throw error;
    }
  };

  return {
    validate,
  };
}
