import { HistorialForm } from "./HistorialForm";
import { HistorialList } from "./HistorialList";

export const Historial = () => {
  return (
    <div className="w-full flex flex-col items-center h-full mt-2 p-4 gap-3">
      <HistorialForm />
      <HistorialList />
    </div>
  );
};
