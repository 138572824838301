import { useState } from "react";

export default function Toggle({ text, setValueOptionsHeader, children }) {
  const [activeSection, setActiveSection] = useState("FACTURA");

  const ClassAvite =
    "px-1 bg-[#003B5B] w-full rounded-tl-lg flex items-center justify-center text-white";
  const ClassInactive =
    "px-1 w-full bg-gray-400 flex items-center text-white justify-center rounded-tl-lg";
  const ClassInactive2 =
    "lg:px-1 md:px-1 px-3 w-full bg-gray-400 flex lg:justify-center text-white md:justify-center justify-between items-center rounded-tr-lg";
  const ClassActive2 =
    "lg:px-1 md:px-1 px-3 text-white w-full bg-[#003B5B] flex  lg:justify-center md:justify-center  justify-between  items-center rounded-tr-lg";

  const handleToggle = (section) => {
    setValueOptionsHeader(section);
    setActiveSection(section);
  };

  return (
    <div className="grid grid-cols-2 cursor-pointer h-10 select-nonelg:w-96 md:w-96 w-full ">
      <div
        onClick={() => handleToggle("FACTURA")}
        className={activeSection === "FACTURA" ? ClassAvite : ClassInactive}
        style={{
          transition: "background-color 0.3s ease",
        }}
      >
        <p>{text}</p>
      </div>
      <div
        onClick={() => handleToggle("OTROS")}
        className={activeSection === "OTROS" ? ClassActive2 : ClassInactive2}
        style={{
          transition: "background-color 0.3s ease",
        }}
      >
        <p>Más opciones</p>
        {children}
      </div>
    </div>
  );
}
