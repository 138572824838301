import { useState } from "react";
import { InformacionGeneral } from "./InformacionGeneral";
import { Historial } from "./Historial";

export const PagosSuscripciones = () => {
  const [showSeccion, setShowSeccion] = useState("General");

  return (
    <section className="p-8 flex flex-col gap-3">
      <div className="w-full p-3 bg-white rounded-lg">
        <h1 className="bg-gray-200 p-6 rounded-lg">
          <span className="ml-4 text-xl font-semibold">
            Pagos y Suscripciones
          </span>
        </h1>
      </div>
      <div className="w-full bg-white">
        <nav className="flex flex-row w-full h-full text-[14px]">
          <button
            className={`border border-t-4 border-t-white border-l-0 border-r-0 flex items-center ${
              showSeccion === "General" && " border-t-[#00314D] border-b-0"
            } p-4 pl-8 pr-8 justify-center h-full`}
            onClick={() => setShowSeccion("General")}
          >
            Información General
          </button>
          <button
            className={`border border-t-4 border-r-0 border-t-white flex items-center ${
              showSeccion === "Historial" &&
              "border-t-[#00314D] border-b-0 border-r"
            } p-4 pl-8 pr-8 justify-center h-full`}
            onClick={() => setShowSeccion("Historial")}
          >
            Historial
          </button>
          <div className="border-b flex-1 w-full"></div>
        </nav>
        {showSeccion === "General" && <InformacionGeneral />}
        {showSeccion === "Historial" && <Historial />}
      </div>
    </section>
  );
};
