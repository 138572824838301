import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import { GetInfoTarifas } from "../../EmisionElectronico/EmitirFactura/services/impuestos";
import ModalErroresExcel from "../shared/ModalErroresExcel";
import { getListV2 } from "../../../services/index"
import { toast } from "react-toastify";
import { EmitirDocContext } from "../../EmisionElectronico/EmitirRetencion/EmitirDocProvider";
import RetencionesTabla from "./RetencionesTabla";
import ResultadoTabla from "./ResultadoTabla";
import ModalProgress from "../shared/ModalProgress";
import ModalAdvertencias from "../shared/ModalAdvertencias";

const SubirRetenciones = () => {
    const {
        setSecuencialesExistentes, setSecuencialesProblemas, secuencialesExistentes, secuencialesProblemas, groupedData, setGroupedData,
    } = useContext(EmitirDocContext);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalAdVisible, setIsModalAdVisibile] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [toUploading, setToUploading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [errorServer, setErrorServer] = useState(false);
    const [documentsProcessed, setDocumentsProcessed] = useState(0);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [erroresDetalles, setErroresDetalles] = useState([])
    const [advertenciasDetalles, setAdvertenciasDetalles] = useState([]);
    const [formasPago, setFormasPago] = useState({});
    const [tarifas, setTarifas] = useState({});
    const [impuestos, setImpuestos] = useState({});
    const [rentas, setRentas] = useState({});
    const [isPostingComplete, setIsPostingComplete] = useState(false);
    const navigate = useNavigate();

    const empresa = useSelector((store) => store.empresa.empresa);
    const { ErrorToast } = useGlobalToastify();
    const inputFileRef = useRef(null);

    const [IsLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadFormasPago = async () => {
            try {
                const formasPago = await getListV2(empresa, "GetFormasPago");
                const formaPagoMap = formasPago._embedded.reduce((map, formaPago) => {
                    if (formaPago.activo) {
                        map[formaPago.codigo] = formaPago.idFormaPagoSri;
                    }
                    return map;
                }, {});

                setFormasPago(formaPagoMap);
            } catch (error) {
                console.error("Error al cargar formas de pago", error);
            }
        };

        const loadServicio = async () => {
            const impimp = await getListV2(empresa, "GetCodImpRetencion", "");
            setImpuestos(impimp._embedded)
        }

        const loadRenta = async () => {
            const rentas = await getListV2(empresa, "GetCodRentaRetencion", "");
            setRentas(rentas._embedded)
        }

        loadFormasPago();
        loadServicio();
        loadRenta();
        GetInfoTarifas(empresa, setTarifas);

    }, []);


    const limpiar = () => {
        setSecuencialesProblemas([]);
        setSecuencialesExistentes([])
        setGroupedData([]);
        setAdvertenciasDetalles([]);
        setProgress(0);
    }

    useEffect(() => {
        if (advertenciasDetalles.length > 0) {
            setIsModalAdVisibile(true);
        }
    }, [advertenciasDetalles]);

    const handleFileImportSeccion = (e) => {
        try {
            const importedFile = e.target.files[0];
            const reader = new FileReader();
            setSecuencialesProblemas([]);
            setSecuencialesExistentes([]);
            setGroupedData([]);
            setAdvertenciasDetalles([]);
            setIsLoading(true);
            setToUploading(true);
            setProgress(0);
    
            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
    
                    const sheetNames = workbook.SheetNames;
    
                    if (sheetNames.includes('PlantillaResidentes')) {
                        const worksheet = workbook.Sheets['PlantillaResidentes'];
                        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
                        if (rows.length > 2) {
                            const rowsWithLineNumbers = rows.slice(3).map((row, index) => {
                                return {
                                    lineNumber: index + 4,  
                                    data: row
                                };
                            });
    
                            const sheetsData = filterCompletelyEmptyRows(rowsWithLineNumbers);
    
                            const facturaData = sheetsData.filteredRows;
    
                            if (facturaData.length > 0) {
                                const groupedData = groupDataBySecuencial(facturaData);
                                setGroupedData(groupedData);
                            } else {
                                console.error("No hay datos en la hoja 'PlantillaResidentes'.");
                            }
                        }
                    } else {
                        console.error("No se encontró la hoja 'PlantillaResidentes' en el archivo.");
                    }
    
                } catch (error) {
                    console.error("Error in reader.onload:", error);
                    setGroupedData([]);
                    setIsModalAdVisibile(false);
                    ErrorToast('El formato del documento no es correcto.');
                    return;
                } finally {
                    setIsLoading(false);
                }
            };
    
            reader.readAsArrayBuffer(importedFile);
            e.target.value = null;
        } catch (error) {
            console.error("Error in handleFileImportSeccion:", error);
            setIsLoading(false);
            ErrorToast("Error al importar archivo excel.");
        }
    };
    

    const filterCompletelyEmptyRows = (rows) => {
        const advertencias = [];
    
        const filteredRows = rows.filter((row, index) => {
            if (!row.data || !Array.isArray(row.data)) {
                console.error(`Fila en la posición ${index + 4} no tiene la propiedad 'data' correctamente estructurada`);
            }
    
            const establecimiento = row.data[2]; 
            const ptoEmision = row.data[3];     
            const secuencial = row.data[4];     
    
            const isRowCompletelyEmpty = !establecimiento && !ptoEmision && !secuencial;
    
            if (!isRowCompletelyEmpty) {
                if (!establecimiento) {
                    advertencias.push({
                        LINEA: index + 4,
                        ESTABLECIMIENTO: 'N/A',
                        PTOEMISION: ptoEmision || 'N/A',
                        SECUENCIAL: secuencial || 'N/A',
                        WARNING: 'Valor de Establecimiento faltante, dicha línea no será considerada completamente.',
                    });
                }
                if (!ptoEmision) {
                    advertencias.push({
                        LINEA: index + 4,
                        ESTABLECIMIENTO: establecimiento || 'N/A',
                        PTOEMISION: 'N/A',
                        SECUENCIAL: secuencial || 'N/A',
                        WARNING: 'Valor de Punto de Emisión faltante, dicha línea no será considerada completamente.',
                    });
                }
                if (!secuencial) {
                    advertencias.push({
                        LINEA: index + 4,
                        ESTABLECIMIENTO: establecimiento || 'N/A',
                        PTOEMISION: ptoEmision || 'N/A',
                        SECUENCIAL: 'N/A',
                        WARNING: 'Valor de Secuencial faltante, dicha línea no será considerada completamente.',
                    });
                }
    
                return true;  
            }
    
            return false;  
        });
    
        setAdvertenciasDetalles(advertencias);
    
        return { filteredRows: filteredRows.map(row => row.data) };
    };
    

    const parseFecha = (fechaStr) => {
        const [day, month, year] = fechaStr.split('/').map(Number);
        return new Date(year, month - 1, day);
    };

    function convertirPeriodo(fecha) {
        const partes = fecha.split("/");

        const mes = partes[1];
        const año = partes[2];

        return `${año}-${mes}`;
    }

    const groupDataBySecuencial = (data) => {

        const grouped = {};
        const errores = [];
        const advertencias = [];
        const fechaActual = new Date();
        const fechaActualSinHora = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate());
        const rangoInferior = new Date(fechaActual);
        rangoInferior.setHours(fechaActual.getHours() - 72);
        const rangoInferiorSinHora = new Date(rangoInferior.getFullYear(), rangoInferior.getMonth(), rangoInferior.getDate());
    
        const cleanCurrencyValue = (value) => {
            if (typeof value === 'string') {
                return parseFloat(value.replace(/[^0-9.-]+/g, '').replace(',', '.'));
            }
            return value;
        };
    
        data.forEach((item, index) => {
            const key = `${Number(item[2])}-${Number(item[3])}-${Number(item[4])}`;
    
            const fechaRet = item[1];
            const establecimiento = item[2];
            const ptoEmision = item[3];
            const secuencial = item[4];
            const codDocSustento = item[5];
            const numeroDocSutento = item[6].trim();
            const identificacionSujeto = item[7];
            const razonSocialSujeto = item[8];
            const direccionDocSustento = item[9];
            const correoDocSustento = item[10];
            const fechaEmision = item[11];
            const fechaContable = item[12];
            const autorizacionDocSustento = item[13];
            const esParteRelacionada = item[14];
            const codSustento = item[15];
            const codigoRenta = item[16];
            const baseImponibleRenta = item[17];
            const codigoIVARenta = item[18];
            const porcentajeRetenerRenta = item[19];
            const valorRetenidoRenta = item[20];
            const baseImponibleIva = item[21];
            const porcentajeRentenerIVA = item[22];
            const valorRetenidoIVA = item[23];
            const codFormaPago = item[24];
            const periodoFiscal = convertirPeriodo(fechaEmision);
    
            const detallesRequeridos = [
                { value: fechaRet, name: 'Fecha Retención' },
                { value: establecimiento, name: 'Establecimiento' },
                { value: ptoEmision, name: 'Punto de Emisión' },
                { value: secuencial, name: 'Secuencial' },
                { value: codDocSustento, name: 'Código Doc. Sustento' },
                { value: numeroDocSutento, name: '# Doc. Sustento' },
                { value: identificacionSujeto, name: 'Identificación Sujeto Retenido' },
                { value: razonSocialSujeto, name: 'Razón Social Sujeto Retenido' },
                { value: direccionDocSustento, name: 'Dirección Doc. Sustento' },
                { value: correoDocSustento, name: 'Correo Doc. Sustento' },
                { value: fechaEmision, name: 'Fecha Emisión' },
                { value: fechaContable, name: 'Fecha Contable' },
                { value: autorizacionDocSustento, name: '# Autorización Doc.' },
                { value: esParteRelacionada, name: 'Es Parte Relacionada' },
                { value: codSustento, name: 'Código Sustento' },
                { value: codigoRenta, name: 'Código Renta' },
                { value: baseImponibleRenta, name: 'Base Imponible Renta' },
                { value: codigoIVARenta, name: 'Código IVA Renta' },
                { value: porcentajeRetenerRenta, name: '% Retener Renta' },
                { value: valorRetenidoRenta, name: 'Valor Retenido Renta' },
                { value: codFormaPago, name: 'Código Forma de Pago' },
            ];
    

            detallesRequeridos.forEach((detalle) => {
                if (!detalle.value && detalle.value !== 0) {
                    errores.push({
                        LINEA: index + 4,
                        ESTABLECIMIENTO: establecimiento || 'N/A',
                        PTOEMISION: ptoEmision || 'N/A',
                        SECUENCIAL: secuencial || 'N/A',
                        ERROR: `${detalle.name} es un campo requerido y está vacío.`,
                    });
                }
    

                if (detalle.name === 'Fecha Retención') {
                    const fechaEmisionDate = parseFecha(fechaRet);
                    const fechaEmisionSinHora = new Date(fechaEmisionDate.getFullYear(), fechaEmisionDate.getMonth(), fechaEmisionDate.getDate());
    
                    if (fechaEmisionSinHora > fechaActualSinHora) {
                        errores.push({
                            LINEA: index + 4,
                            ESTABLECIMIENTO: establecimiento || 'N/A',
                            PTOEMISION: ptoEmision || 'N/A',
                            SECUENCIAL: secuencial || 'N/A',
                            ERROR: `La ${detalle.name} no puede ser superior a la fecha actual.`,
                        });
                    } 
                    // else if (fechaEmisionSinHora < fechaActualSinHora && fechaEmisionSinHora >= rangoInferiorSinHora) {
                    //     advertencias.push({
                    //         LINEA: index + 4,
                    //         ESTABLECIMIENTO: establecimiento || 'N/A',
                    //         PTOEMISION: ptoEmision || 'N/A',
                    //         SECUENCIAL: secuencial || 'N/A',
                    //         WARNING: `La ${detalle.name} es menor a la fecha actual, pero dentro del rango de 72 horas.`,
                    //     });
                    // } 
                    else if (fechaEmisionSinHora < rangoInferiorSinHora) {
                        advertencias.push({
                            LINEA: index + 4,
                            ESTABLECIMIENTO: establecimiento || 'N/A',
                            PTOEMISION: ptoEmision || 'N/A',
                            SECUENCIAL: secuencial || 'N/A',
                            WARNING: `La ${detalle.name} es mayor a 72 horas antes de la fecha actual.`,
                        });
                    }
                }
            });

            let codSustentoFormateado = String(codSustento).padStart(2, '0');
            let codDocSustentoFormateado = String(codDocSustento).padStart(2, '0');
            
            if (!grouped[key]) {
                grouped[key] = {
                    FECHARETENCION: fechaRet,
                    ESTABLECIMIENTO: establecimiento,
                    PTOEMISION: ptoEmision,
                    SECUENCIAL: secuencial,
                    CODSUSTENTO: codSustentoFormateado,
                    CODDOCSUSTENTO: codDocSustentoFormateado,
                    PARTEREL: esParteRelacionada.toUpperCase(),
                    PERIODOFISCAL: periodoFiscal,
                    IDENTIFICACIONSUJETO: identificacionSujeto,
                    RAZONSOCIAL: razonSocialSujeto,
                    CORREODOCSUSTENTO: correoDocSustento,
                    DIRECCIONCODSUSTENTO: direccionDocSustento,
                    DOCSSUSTENTO: [],
                    ADICIONAL: [],
                };
            }
            else{
                errores.push({
                    LINEA: index + 4,
                    ESTABLECIMIENTO: establecimiento || 'N/A',
                    PTOEMISION: ptoEmision || 'N/A',
                    SECUENCIAL: secuencial || 'N/A',
                    ERROR: `Solo se permite registrar una línea por cada retención.`,
                });
            }
    
            const adicionalesRetencion = [];
            for (let i = 25; i <= 30; i += 2) {
                const infAdicional = item[i];
                const valorAdicional = item[i + 1];
                if (infAdicional && valorAdicional) {
                    adicionalesRetencion.push({
                        INFADICIONAL: infAdicional,
                        VALORINFADICIONAL: valorAdicional,
                    });
                }
            }
    
            const docSustento = {
                PAGO: codFormaPago, 
                FECHAEMISION: fechaEmision,
                FECHACONTABLE: fechaContable,
                NUMAUTDOCSUSTENTO: autorizacionDocSustento,
                NUMDOCSUSTENTO: numeroDocSutento,
                IMPORTETOTAL: 0,
                TOTALSINIMPUESTO: 0,
                VALORIMPUESTO: 0,
                RENTA: [{
                    CODIGORENTA: codigoRenta,
                    BASEIMPONIBLERENTA: cleanCurrencyValue(baseImponibleRenta || 0),
                    CODIGOIVARENTA: Number(codigoIVARenta),
                    PORCENTAJERETRENTA: porcentajeRetenerRenta || 0,
                    VALORRETRENTA: cleanCurrencyValue(valorRetenidoRenta || 0),
                }],
                IVA: baseImponibleIva !== undefined ? [{
                    BASEIMPONIBLEIVA: cleanCurrencyValue(baseImponibleIva || 0),
                    PORCENTAJERETIVA: porcentajeRentenerIVA || 0,
                    VALORRETIVA: cleanCurrencyValue(valorRetenidoIVA || 0),
                }] : [],
            };
    
            grouped[key].DOCSSUSTENTO.push(docSustento);
    

            grouped[key].ADICIONAL.push(...adicionalesRetencion);
        });
    
        setAdvertenciasDetalles(advertencias);
        setErroresDetalles(errores);

        Object.keys(grouped).forEach(key => {
            const item = grouped[key];
    
            item.DOCSSUSTENTO.forEach(doc => {
                let precioBruto = 0; 
                let totalTarifa = 0; 
    
                if (doc.RENTA && doc.RENTA.length > 0) {
                    doc.RENTA.forEach(detalle => {
                        precioBruto += detalle.BASEIMPONIBLERENTA || 0;
                        const iva = tarifas[parseInt(detalle.CODIGOIVARENTA)] || 0;
                        totalTarifa += detalle.BASEIMPONIBLERENTA * (iva / 100) || 0;
                    });
                }
    
                const valorImpuesto = totalTarifa; 
                const totalSinImpuesto = precioBruto; 
                const importeTotal = totalSinImpuesto + valorImpuesto; 
    
                doc.VALORIMPUESTO = valorImpuesto; 
                doc.TOTALSINIMPUESTO = totalSinImpuesto;
                doc.IMPORTETOTAL = importeTotal;
    
                doc.descripcionTipoPago = null;
                doc.tipoPago = formasPago[doc.PAGO] || "";
                doc.valor = doc.IMPORTETOTAL; 
            });
        });
    
        return Object.values(grouped).sort((a, b) => a.SECUENCIAL - b.SECUENCIAL);
    };
    

    const chunkArray = (array, size) => {
        const chunkedArray = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    };


    const handleCloseModal = () => {
        setIsModalVisible(false);
        setIsLoading(false);
    };

    const handleAdvertenciaClose = () => {
        setIsModalAdVisibile(false);
        setIsLoading(false);
    };


    const downloadTemplate = async () => {
        try {
            const response = await fetch('/PlantillaRetenciones.xlsx', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'PlantillaRetenciones.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Failed to download file');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };


    function convertDate(dateStr) {
        const [day, month, year] = dateStr.split('/').map(Number);

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            throw new Error('Input must be in dd/mm/yyyy format');
        }

        const formattedDate = `${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}/${year}`;
        return formattedDate;
    }

    const guardarDocumentos = async () => {
        try {
            if (erroresDetalles.length > 0) {
                setIsLoading(true);
                setIsModalVisible(true);
                return;
            }

            setProgress(1);
            setIsSending(true);
            updateProgress();

            const construirDocumento = async (data) => {
                const {
                    FECHARETENCION,
                    ESTABLECIMIENTO,
                    PTOEMISION,
                    SECUENCIAL,
                    CODSUSTENTO,
                    CODDOCSUSTENTO,
                    PARTEREL,
                    PERIODOFISCAL,
                    IDENTIFICACIONSUJETO,
                    RAZONSOCIAL,
                    CORREODOCSUSTENTO,
                    DIRECCIONCODSUSTENTO,
                    DOCSSUSTENTO,
                    ADICIONAL,
                } = data;

                let informacionAdicional = ADICIONAL.map((x) => ({
                    Descripcion: x['INFADICIONAL'],
                    Valor: x['VALORINFADICIONAL'],
                }));

                const obtenerCodigoImpuesto = (codigo) => {
                    const renta = rentas.find(item => item.codigo === codigo.toString());

                    let codigoImpuesto;

                    if (renta) {
                        codigoImpuesto = renta.idImpuestoRetencion;

                        return {
                            codigoImpuesto: codigoImpuesto,
                        };
                    } else {
                        return { error: "Impuesto no encontrado para el porcentaje dado" };
                    }
                }

                const retenciones = [];
                let detalleRenta = DOCSSUSTENTO[0].RENTA?.map((detalle) => {

                    const impuestoRetencion = obtenerCodigoImpuesto(detalle['CODIGORENTA']);

                    return {
                        BaseImponible: detalle['BASEIMPONIBLERENTA'].toFixed(2),
                        Codigo: impuestoRetencion.codigoImpuesto,
                        CodigoRetencion: detalle['CODIGORENTA'],
                        Dividendos: null,
                        NumCajBan: null,
                        PorcentajeRetener: detalle['PORCENTAJERETRENTA'],
                        PrecCajBan: null,
                        ValorRetenido: detalle['VALORRETRENTA'].toFixed(2)
                    };
                });

                const obtenerImpuesto = (porcentaje) => {
                    const impuesto = impuestos.find(item => item.porcentaje === porcentaje);
                    let codigo = 0;
                    let codigoRetencion;

                    if (impuesto) {
                        codigo = impuesto.idImpuestoRetencion;
                        codigoRetencion = impuesto.codigo;

                        return {
                            codigo: codigo,
                            codigoRetencion: codigoRetencion
                        };
                    } else {
                        return { error: "Impuesto no encontrado para el porcentaje dado" };
                    }
                }

                let detalleIva = [];

                if (DOCSSUSTENTO[0].IVA && DOCSSUSTENTO[0].IVA.length > 0) {
                    detalleIva = DOCSSUSTENTO[0].IVA.map((detalle) => {
                        const impuestoDetalle = obtenerImpuesto(detalle['PORCENTAJERETIVA']);

                        return {
                            BaseImponible: detalle['BASEIMPONIBLEIVA'].toFixed(2),
                            Codigo: impuestoDetalle.codigo,
                            CodigoRetencion: impuestoDetalle.codigoRetencion,
                            Dividendos: null,
                            NumCajBan: null,
                            PorcentajeRetener: detalle['PORCENTAJERETIVA'],
                            PrecCajBan: null,
                            ValorRetenido: detalle['VALORRETIVA'].toFixed(2)
                        };
                    });
                }

                retenciones.push(...detalleRenta, ...detalleIva);

                return {
                    Ambiente: process.env.REACT_APP_ENVIRONMENT,
                    IdEmpresa: empresa.idEmpresa,
                    Establecimiento: ESTABLECIMIENTO,
                    PuntoEmision: PTOEMISION,
                    Secuencial: SECUENCIAL,
                    IdentificacionContribuyente: IDENTIFICACIONSUJETO,
                    RazonSocialContribuyente: RAZONSOCIAL,
                    Direccion: DIRECCIONCODSUSTENTO,
                    Correo: CORREODOCSUSTENTO.replace(/,/g, ";").replace(/\s+/g, ""),
                    IsPartRel: PARTEREL.toUpperCase() !== "NO" ? true : false,
                    FechaEmision: convertDate(FECHARETENCION),
                    PeriodoFiscal: PERIODOFISCAL,
                    DocsSustento: DOCSSUSTENTO.map((doc) => {
                        return {
                            CodDocSustento: CODDOCSUSTENTO,
                            CodSustento: CODSUSTENTO,
                            FechaEmisionDocSustento: convertDate(doc.FECHAEMISION) || "",
                            FechaRegistroContable: convertDate(doc.FECHACONTABLE) || "",
                            ImporteTotal: doc.IMPORTETOTAL.toFixed(2),
                            ImpuestosDocSustento: [
                                {
                                    BaseImponible: doc.RENTA?.[0]?.BASEIMPONIBLERENTA.toFixed(2),
                                    CodImpuestoDocSustento: null,
                                    CodigoPorcentaje: doc.RENTA?.[0]?.CODIGOIVARENTA,
                                    Tarifa: null,
                                    ValorImpuesto: doc.VALORIMPUESTO.toFixed(2)
                                }
                            ],
                            NumAutDocSustento: doc.NUMAUTDOCSUSTENTO,
                            NumDocSustento: doc.NUMDOCSUSTENTO,
                            Pago: [{
                                descripcionTipoPago: null,
                                tipoPago: doc.tipoPago || "",
                                valor: doc.IMPORTETOTAL.toFixed(2)
                            }],
                            PagoLocExt: "01",
                            PaisEfecPago: 593,
                            Reembolsos: null,
                            Retenciones: retenciones,
                            TotalSinImpuestos: doc.TOTALSINIMPUESTO.toFixed(2)
                        }
                    }),
                    infoAdicional: informacionAdicional
                };
            };

            const documentosList = await Promise.all(
                Object.values(groupedData).map(async (data) => construirDocumento(data))
            );


            setTotalDocuments(documentosList.length);
            const chunkedDocumentos = chunkArray(documentosList, 10);
            let hasErrors = false;
            setDocumentsProcessed(0);

            for (const chunk of chunkedDocumentos) {
                try {
                    setDocumentsProcessed(prev => prev + chunk.length);

                    const response = await axios.post("/api/Retenciones/GuardarRetencionExcel", chunk);


                    if (response.data.erroresExistentes.length > 0 || response.data.erroresProcesos.length > 0 ) {
                        hasErrors = true;

                        const secuencialesExistentes = response.data.erroresExistentes.map(error => error.retencion);

                        setSecuencialesExistentes(prev => [...prev, ...secuencialesExistentes]);


                        const otrosErrores = response.data.erroresProcesos.map(error => ({
                            retencion: error.retencion,
                            mensaje: error.mensaje
                        }));

                        setSecuencialesProblemas(prev => [...prev, ...otrosErrores]);

                    }

                } catch (error) {
                    hasErrors = true;
                    setErrorServer(true);
                    ErrorToast("Error al subir las retenciones");
                }
            }

            if (!hasErrors && erroresDetalles.length === 0) {
                toast.success("Retenciones agregadas con éxito.");
                inputFileRef.current.value = null;
                setGroupedData([]);
                setIsSending(false);
                setIsLoading(false);
                setToUploading(false);
                setIsPostingComplete(true);
                navigate('/emision/reporte/general');
            } else {
                setIsSending(false);
                setToUploading(secuencialesExistentes.length !== 0 && secuencialesProblemas.length !== 0);
            }

        } catch (error) {
            setErrorServer(true);
            ErrorToast("Error al procesar documentos");
            
        }
    };

    const updateProgress = () => {
        const progressPercentage = Math.round((documentsProcessed / totalDocuments) * 100);

        const incrementProgress = (currentProgress, targetProgress) => {
            if (currentProgress < targetProgress) {
                const newProgress = Math.min(currentProgress + 1, targetProgress);
                setProgress(newProgress);
                setTimeout(() => incrementProgress(newProgress, targetProgress), 100);
            }
        };

        incrementProgress(progress, progressPercentage);
    };


    useEffect(() => {
        if (totalDocuments > 0 && documentsProcessed === totalDocuments && isPostingComplete) {
            setProgress(100);
            setTimeout(() => {
                setIsSending(false);
            }, 2000);
        } else {
            updateProgress();
        }
    }, [documentsProcessed, totalDocuments, isPostingComplete]);


    return (
        <div className="space-y-6 xl:mx-4 my-2">
            <ModalErroresExcel
                isVisible={isModalVisible}
                erroresDetalles={erroresDetalles}
                onClose={handleCloseModal}
            />
            <ModalAdvertencias
                isVisible={isModalAdVisible}
                advertenciasDetalles={advertenciasDetalles}
                onClose={handleAdvertenciaClose}
            />
            <ModalProgress
                isVisible={isSending}
                onClose={() => setIsSending(false)}
                progress={progress}
            />
            <div className="bg-white shadow  dark:bg-gray-900 sm:rounded-lg md:py-2 md:px-4 py-2 px-2">
                <div className=" py-2">
                    <h2 className=" pl-4 text-2xl p-5 rounded-lg bg-[#D6D6D6] dark:bg-gray-700   font-semibold text-gray-700 dark:text-gray-200">
                        Emisión masiva de retenciones
                    </h2>
                </div>

                <div className="flex flex-row flex-wrap gap-4 my-4">
                    <button
                        className={' items-center px-4 py-2 border border-transparent shadow-sm text-sm leading-5 font-body rounded-md text-white   focus:outline-none focus:ring-2 bg-green-500 '}
                        onClick={downloadTemplate}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="inline mr-2 -ml-1 w-5 h-5">
                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                        </svg>

                        Descargar plantilla
                    </button>
                    <div className="relative inline-block">
                        <input
                            type="file"
                            onChange={handleFileImportSeccion}
                            accept=".xlsx, .xls"
                            className="absolute inset-0 opacity-0 cursor-pointer"
                            ref={inputFileRef}
                        />

                        <button
                            type="button"
                            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={() => inputFileRef.current?.click()}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor" className="inline mr-2 -ml-1 w-5 h-5">
                                <path d="M0 55.2L0 426c0 12.2 9.9 22 22 22c6.3 0 12.4-2.7 16.6-7.5L121.2 346l58.1 116.3c7.9 15.8 27.1 22.2 42.9 14.3s22.2-27.1 14.3-42.9L179.8 320l118.1 0c12.2 0 22.1-9.9 22.1-22.1c0-6.3-2.7-12.3-7.4-16.5L38.6 37.9C34.3 34.1 28.9 32 23.2 32C10.4 32 0 42.4 0 55.2z" />
                            </svg>
                            Seleccionar archivo
                        </button>
                    </div>

                    <div className=" cursor-pointer w-10 h-10 rounded-full inline-flex items-center justify-center">
                        <button
                            type="button"
                            onClick={limpiar}
                            className="inline-flex h-10 w-10 mt-1 cursor-pointer shadow-md  items-center justify-center rounded-xl  transition dark:bg-gray-900 dark:hover:bg-gray-800"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-6 w-6 dark:text-white"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                            </svg>

                            <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white  rounded-md bg-gray-800 group-hover:flex text-center p-1">
                                <p className="text-white dark:text-black mx-auto text-sm ">
                                    Limpiar
                                </p>
                            </div>
                        </button>
                    </div>
                </div>

                {IsLoading ? (
                    <div className="flex items-center justify-center py-4">
                        <svg
                            aria-hidden="true"
                            role="status"
                            className={`inline mr-3 w-8 h-8 animate-spin`}
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                ) :

                groupedData.length > 0 ? !toUploading && erroresDetalles.length === 0  && !errorServer? (
                        <ResultadoTabla />

                    ) : (
                        <RetencionesTabla />
                    ) : (
                        <div className="py-4 flex flex-col justify-center items-center h-[65vh]">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="inline mr-2 -ml-1 w-5 h-5 fill-yellow-400">
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                            </svg>
                            <p className="text-2xl">No existe informacion cargada.</p>
                        </div>)}
                <div className="flex flex-row flex-wrap gap-2 mt-8 justify-end">
                    {
                        (toUploading && groupedData.length > 0) && (
                            <button
                                onClick={guardarDocumentos}
                                type="submit"
                                className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
                            >
                                {
                                    IsLoading ? (
                                        <svg
                                            aria-hidden="true"
                                            role="status"
                                            className={`inline mr-3 w-8 h-8 animate-spin`}
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    ) : (<>Subir</>)
                                }
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SubirRetenciones;