import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useVistaPrevia = () => {
  const [IsLoading, setIsLoading] = useState(false);

  const OpenVistaPrevia = async (data) => {
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const GetVistaPreviaFactura = async (data) => {
    try {
      //console.log("GetVistaPreviaFactura 6666666666666666");
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/factura", data);
    
      /* console.log(res.data);
      console.log(res.status); */
      return res.data;
    } catch (ex) {
      //console.log("ERROR "+ex);
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaProforma = async (data) => {
    try {
      //console.log("GetVistaPreviaFactura 6666666666666666");
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/factura", data);
    
      /* console.log(res.data);
      console.log(res.status); */
      return res.data;
    } catch (ex) {
      //console.log("ERROR "+ex);
    } finally {
      setIsLoading(false);
    }
  };
  const GetVistaPreviaFacturaPTVenta = async (data) => {
    try {
      //console.log("GetVistaPreviaFacturaPTVenta 99999999999999999");
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/FacturaPTVenta", data);
      //console.log(res.data);
      /* if (res.status !== 200) {
        return res.data;
        console.log("info ok ")
      } */
      if (res.status === 200) {
      //  console.log("info ok ")
        return res.data;
        
      }
      //return res.data;
    } catch (ex) {
     // console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaGuiaRemision = async (data) => {
    try {
      setIsLoading(true);
      // console.log(JSON.stringify(data));
      let res = await axios.post("api/VistaPrevia/guiaremision", data);
      // console.log(res.data);
      return res.data;
    } catch (ex) {
     // console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaLiquidacion = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/liquidacion", data);
      // console.log(res.data);
      return res.data;
    } catch (ex) {
    //  console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaRetencion = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/retencion", data);
      // console.log(res.data);
      return res.data;
    } catch (ex) {
   //   console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaNotaDebito = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/notadebito", data);
      // console.log(res.data);
      return res.data;
    } catch (ex) {
    //  console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const GetVistaPreviaNotaCredito = async (data) => {
    try {
      setIsLoading(true);
      let res = await axios.post("api/VistaPrevia/notacredito", data);
      // console.log(res.data);
      return res.data;
    } catch (ex) {
    //  console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    IsLoading,
    GetVistaPreviaFactura,
    GetVistaPreviaGuiaRemision,
    GetVistaPreviaLiquidacion,
    GetVistaPreviaRetencion,
    GetVistaPreviaNotaDebito,
    GetVistaPreviaNotaCredito,
    OpenVistaPrevia,
    GetVistaPreviaFacturaPTVenta,
    GetVistaPreviaProforma
   
  };
};

export default useVistaPrevia;
