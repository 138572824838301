import { PostElement } from "../../../../services";
import UserAgregadoIcon from "./Icons/userAgregado";
import UserErrorIcon from "./Icons/userErrorIcon";

export default function serviceAddContribuyente() {
  const postContribuyente = async (
    empresa,
    TotalValues,
    setMessageSubmit,
    setMessageDescripcionSubmit,
    setsvgSubmit
  ) => {
    try {
      setMessageSubmit("Agregando contribuyente...");
      setMessageDescripcionSubmit("Por favor espere un momento.");
      await PostElement(empresa, "PostContribuyente", TotalValues);
      setMessageSubmit("Contribuyente agregado");
      setMessageDescripcionSubmit(
        "El contribuyente ha sido agregado exitosamente."
      );
      setsvgSubmit(<UserAgregadoIcon />);
    } catch (ex) {
      setMessageSubmit("Ocurrio un error...");
      setMessageDescripcionSubmit(
        "No se ha podido agregar el contribuyente. Por favor, verifique su conexión a internet, asegúrese de que los datos sean correctos y actualice el portal para intentarlo nuevamente"
      );
      setsvgSubmit(<UserErrorIcon />);
      throw ex;
    }
  };

  return { postContribuyente };
}
