import React, { useEffect, useState } from "react";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import { useForm } from "react-hook-form";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import LengthCounter from "../../../features/components/LengthCounter";

const InfoAdicionalModals = ({
  InformacionAdicionalList,
  setInformacionAdicionalList,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      nombre: "",
      valor: ""
    },
    mode: "onSubmit"
  });

  const [IsLoading, setIsLoading] = useState(false);

  const { ErrorToast } = useGlobalToastify();

  const handleDeleteElement = (index) => {
    InformacionAdicionalList.splice(index, 1);
    setInformacionAdicionalList([...InformacionAdicionalList]);
  };

  const onClickHandlerInfoAdicional = (values) => {
    setIsLoading(true)

    if (values.nombre.length < 1 || values.valor.length < 1) {
      setTimeout(() => { setIsLoading(false) }, 2000)
      return ErrorToast("Nombre y Valor son requeridos!");
    }


    if (values.nombre.trim().length < 1 || values.valor.trim().length < 1) {
      setTimeout(() => { setIsLoading(false) }, 2000)
      return ErrorToast("Remover espacios en blanco y agregar caracteres validos en la información adicional!");
    }

    if (InformacionAdicionalList.length <= 2) {
      setInformacionAdicionalList([{ ...values }, ...InformacionAdicionalList]);
      setValue("nombre", "");
      setValue("valor", "");
      setTimeout(() => { setIsLoading(false) }, 2000)
    } else {
      ErrorToast("No puedes agregar más de 3 detalles adicionales a un producto!");
      setTimeout(() => { setIsLoading(false) }, 2000)
    }

  };

  // useEffect(() => {
  //   Object.values(errors)[0] && ErrorToast(Object.values(errors)[0].message);
  // }, [ErrorToast, errors]);

  return (
    <div className="py-0  flex-wrap justify-between mb-0 my-3">
     
      <div className="w-full pb-3 grid md:grid-cols-6 md:gap-2 mb-2 md:mb-0">
        <div className="md:col-span-2 relative z-0 mb-2 w-full group">

        <label
            htmlFor="nombre"

          >
            NOMBRE <LengthCounter itemLength={watch("nombre")} />
          </label>
          <input
            type="text"
            name="nombre"
            className={errors["nombre"] ? StyleInputError : StyleInput}
            {...register("nombre", {
              maxLength: {
                value: 300,
                message: "Nombre no debe tener más de 300 carácteres!",
              },
              minLength: {
                value: 1,
                message: "Nombre debe tener mínimo 1 carácter",
              },
            })}
            maxLength="300"
          />
         
        </div>

        <div className="md:col-span-3 relative z-0 mb-2 w-full group">
        <label
            htmlFor="valor"
          
          >
            DETALLE <LengthCounter itemLength={watch("valor")} />
          </label>
          <input
            type="text"
            className={errors["valor"] ? StyleInputError : StyleInput}
            {...register("valor", {
              maxLength: {
                value: 300,
                message: "Valor no debe tener más de 300 carácteres!",
              },
              minLength: {
                value: 1,
                message: "Valor debe tener mínimo 1 carácter",
              },
            })}
            maxLength="300"
            name="valor"
          />
        
        </div>

        <div className="relative z-0 mb-2 w-full group pt-5">
          <button
            onClick={handleSubmit(onClickHandlerInfoAdicional)}
            type="button"
            className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
          >
            {
              IsLoading ? 'Cargando...' : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-5 h-5 dark:stroke-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </>
              )
            }
          </button>
        </div>
      </div>

      {InformacionAdicionalList.length > 0 && 
        <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
        <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase divide-y bg-gray-100 dark:bg-gray-600 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-1 px-6 dark:text-white ">
                NOMBRE
              </th>
              <th scope="col" className="py-1 px-6 dark:text-white  ">
                DETALLE
              </th>
              <th scope="col" className="py-1 px-6"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600 bg-white">
            {InformacionAdicionalList.length === 0 ? (
              <MesageWarningTable
                colSpan="3"
                message="No se ha ingresado información"
              />
            ) : null}
            {InformacionAdicionalList.map((el, index) => (
              <tr
                key={index}
                className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600  "
              >
                <td className="py-1 px-6">{el.nombre}</td>
                <th
                  scope="row"
                  className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {el.valor}
                </th>
                <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                  <button onClick={() => handleDeleteElement(index)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      }
    
    </div>
  );
};

export default InfoAdicionalModals;
