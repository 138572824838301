//Rutas de la pagina general

import EmitirFactura from "../EmisionElectronico/EmitirFactura";
import EmitirGuiaRemision from "../EmisionElectronico/EmitirGuiaRemision";
import EmitirLiquidacion from "../EmisionElectronico/EmitirLiquidacion";
import EmitirNotaCredito from "../EmisionElectronico/EmitirNotaCredito";
import EmitirNotaDebito from "../EmisionElectronico/EmitirNotaDebito";
import EmitirRetencion from "../EmisionElectronico/EmitirRetencion";
import { CrearBodega } from "../Inventario/Bodegas/Crear/CrearBodega";
import { CrearCategoria } from "../Inventario/Categorias/Crear/CrearCategoria";
import { InventarioOpciones } from "../Inventario/Configuracion";
import { CrearProducto } from "../Inventario/Productos/Crear/CrearProducto";
import { CrearSeccion } from "../Inventario/Secciones/Crear/CrearSeccion";
import { TransaccionesMenu } from "../Inventario/TiposOperaciones/Transacciones/TransaccionesMenu";
import { TransaccionesEmitidas } from "../Inventario/TiposOperaciones/TransaccionesEmitidas/TransaccionesEmitidas";
import ReporteContribuyente from "../Mantenimiento/Contribuyentes";
import AddContribuyenteForm from "../Mantenimiento/Contribuyentes/AddContribuyente/AddContriyenteForm";
import EditContribuyenteForm from "../Mantenimiento/Contribuyentes/EditContribuyente/EditContribuyenteForm";
import EditEmpresaList from "../Mantenimiento/EditEmpresaFromList";
import Establecimientos from "../Mantenimiento/Establecimiento";
import InfoEmpresa from "../Mantenimiento/InfoEmpresa/FormEmpresa";
import ListaEmpresas from "../Mantenimiento/ListaEmpresas";
import ProductosServicios from "../Mantenimiento/ProductosServicios";
import ReporteCliente from "../ReportesEmision/ReporteCliente";
import ReporteDocGeneradoCliente from "../ReportesEmision/ReporteDocGenerado/IndexDocGenerado";
import ReporteEmitidosGeneralUsuario from "../ReportesEmision/ReporteEmisionGeneral";
import ReporteFactura from "../Mantenimiento/Usuarios/index";
import ReporteSolicitudMasivo from "../ReportesEmision/ReporteSolicitudMasivo";
import ReporteEmitidosGeneralContribuyente from "../ReportesEmision/ReportesUsuarios";
import FacturacionElec from "../Suscripciones/FacturacionElec";
import FirmaElec from "../Suscripciones/FirmaElec";
import FormTaxflash from "../Suscripciones/TaxFlash/FormTaxflash";
import Ingreso from "../Inventario/TiposOperaciones/Transacciones/Ingreso/Ingreso";
import Egreso from "../Inventario/TiposOperaciones/Transacciones/Egresos/Egreso";
import Transferencias from "../Inventario/TiposOperaciones/Transacciones/Transferencias/Transferencias";
import AjusteInventario from "../Inventario/TiposOperaciones/Transacciones/AjusteInventario/AjusteInventario";
import InfoUsuario from "../Mantenimiento/InfoUsuario/InfoUsuario";

import { PagosSuscripciones } from "../Dashboard/Indicadores/PagosYSuscripciones/PagosSuscripcionesIndex";
import { EmitirProforma } from "../EmisionElectronico/EmitirProforma/EmitirProforma";

import SubirFacturasGeneral from "../CargaMasiva/SubirFacturas/index"
import { Component } from "react";
import SubirRetencionesGeneral from "../CargaMasiva/SubirRetenciones";


export const SubRoutes = [
  {
    path: "/emision/reporte/factura",
    Component: ReporteFactura,
  },
  {
    path: "/pagosSuscripciones",
    Component: PagosSuscripciones,
  },
  {
    path: "/emision/generar/factura",
    Component: EmitirFactura,
  },
  {
    path: "/carga/generar/factura",
    Component: SubirFacturasGeneral
  },
  {
    path: "/carga/generar/retencion",
    Component: SubirRetencionesGeneral
  },
  {
    path: "/emision/generar/masivo",
    Component: ReporteSolicitudMasivo,
  },
  {
    path: "/inventario/configuracion",
    Component: InventarioOpciones,
  },
  {
    path: "/emision/reporte/doc-generado",
    Component: ReporteDocGeneradoCliente,
  },
  {
    path: "/emision/generar/guiaRemision",
    Component: EmitirGuiaRemision,
  },

  {
    path: "/emision/generar/notaCredito",
    Component: EmitirNotaCredito,
  },
  {
    path: "/emision/generar/notadDebito",
    Component: EmitirNotaDebito,
  },
  {
    path: "/emision/reporte/editar-usuario",
    Component: InfoUsuario,
  },
  {
    path: "/emision/generar/retencion",
    Component: EmitirRetencion,
  },
  {
    path: "/emision/reporte/general",
    Component: ReporteEmitidosGeneralUsuario,
  },
  {
    path: "/emision/reporte/cliente",
    Component: ReporteCliente,
  },
  {
    path: "/emision/reporte/generalUsuario",
    Component: ReporteEmitidosGeneralContribuyente,
  },
  {
    path: "/emision/reporte/servicios",
    Component: ProductosServicios,
  },
  {
    path: "/emision/reporte/establecimientos",
    Component: Establecimientos,
  },
  {
    path: "/emision/reporte/usuarios",
    Component: ReporteFactura,

  },
  {
    path: "/emision/reporte/contribuyentes",
    Component: ReporteContribuyente,
  },
  {
    path: "/emision/reporte/empresa",
    Component: InfoEmpresa,
  },
  {
    path: "/emision/reporte/edit-empresa",
    Component: EditEmpresaList,
  },
  {
    path: "/emision/reporte/lista-empresas",
    Component: ListaEmpresas,
  },
  {
    path: "/mantenimiento/contribuyente/add",
    Component: AddContribuyenteForm,
  },
  {
    path: "/mantenimiento/contribuyente/edit/:id",
    Component: EditContribuyenteForm,
  },
  {
    path: "/suscripciones/firma/nuevafirma",
    Component: FirmaElec,
  },
  {
    path: "/suscripciones/facturacion/nuevafactura",
    Component: FacturacionElec,
  },
  {
    path: "/suscripciones/taxflash/nuevoplan",
    Component: FormTaxflash,
  },
  {
    path: "/emision/generar/liquidacion",
    Component: EmitirLiquidacion,
  },
  {
    path: "/inventario/bodegas/crear",
    Component: CrearBodega,
  },
  {
    path: "/inventario/secciones/crear",
    Component: CrearSeccion,
  },
  {
    path: "/inventario/categorias/crear",
    Component: CrearCategoria,
  },
  {
    path: "/inventario/productos/crear",
    Component: CrearProducto,
  },
  {
    path: "inventario/operaciones/transacciones",
    Component: TransaccionesMenu,
  },
  {
    path: "inventario/operaciones/transaccionesEmitidas",
    Component: TransaccionesEmitidas,
  },
  {
    path: "/inventario/operaciones/transacciones/ingresos",
    Component: Ingreso,
  },
  {
    path: "/inventario/operaciones/transacciones/egresos",
    Component: Egreso,
  },
  {
    path: "/inventario/operaciones/transacciones/transferencias",
    Component: Transferencias,
  },
  {
    path: "/inventario/operaciones/transacciones/ajuste-inventario",
    Component: AjusteInventario,
  },
  {
    path: "inventario/operaciones/comprasInventario",
    Component: "",
  },
  {
    path: "inventario/informes/analisis",
    Component: "",
  },
  {
    path: "inventario/informes/kardex",
    Component: "",
  },
  {
    path: "inventario/informes/inventario",
    Component: "",
  },
  {
    path: "inventario/informes/valoracion",
    Component: "",
  },
  {
    path: "inventario/informes/movimientos",
    Component: "",
  },
];
