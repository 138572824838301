export const InformacionGeneral = () => {
  return (
    <div className="w-full flex md:flex-row flex-col pb-7 items-center h-full mt-2 gap-3">
      <img src="/pagosSuscripciones.png" alt="Informacion General" />
      <div className="flex flex-col gap-8 justify-center ">
        <h1 className="text-2xl font-semibold self-center">Estado del Plan</h1>
        <div className="flex flex-col gap-8 flex-1 text-md">
          <label
            htmlFor=""
            className="flex flex-row items-center justify-between"
          >
            Plan Contratado:
            <input
              type="text"
              value={"1500 Comprobantes"}
              className="border self-end p-1"
            />
          </label>
          <label
            htmlFor=""
            className="flex flex-row  items-center justify-between"
          >
            Comprobantes Emitidos:
            <input type="text" value={"900"} className="border self-end p-1" />
          </label>
          <label
            htmlFor=""
            className="flex flex-row justify-between items-center gap-4"
          >
            Comprobantes Disponibles:
            <input type="text" value={"800"} className="border self-end p-1" />
          </label>
        </div>
      </div>
    </div>
  );
};
