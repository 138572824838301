import moment from "moment";
import { useEffect, useState } from "react";

export const useHours = ({
    fechaActual,
    FechaInput,
    toggleModal,

}) => {
  const [isOver72Hours, setIsOver72Hours] = useState(false);

    
    useEffect(() => {
        // console.log(fechaActual);
    
        // console.log(FechaInput);
        const diffInDays = moment(fechaActual).diff(FechaInput, "hours");
        const isOver72Hours = diffInDays >= 72;
        setIsOver72Hours(isOver72Hours);
        if (isOver72Hours) {
          {
            toggleModal();
          }
        }
        // console.log(isOver72Hours);
      }, [FechaInput]);
    
    return {
        useHours,
        isOver72Hours
    };
    }