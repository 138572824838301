import React from 'react';

const ModalErroresExcel = ({ isVisible, erroresDetalles, onClose }) => {
    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${isVisible ? '' : 'hidden'}`}
            onClick={onClose}
        >
            <div
                className="relative w-full max-w-4xl h-auto max-h-[80vh] bg-white rounded-lg overflow-hidden"
                onClick={handleModalClick}
            >
                <button
                    type="button"
                    onClick={onClose}
                    className="absolute top-2 right-2 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                <div className="p-6">
                    <h2 className="font-bold text-2xl mb-4 text-gray-800 pb-2 border-b border-gray-600">Listado de Errores Excel</h2>
                    <p className="py-2 text-gray-600">El archivo excel no puede ser procesado por información errónea</p>

                    {erroresDetalles.length > 0 && (
                        <div className="mt-4 max-h-[60vh] overflow-y-auto">
                            <table className="min-w-full divide-y divide-gray-200 text-sm text-center">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-3 py-2 border-r border-gray-300">N. Línea Excel</th>
                                        <th className="px-3 py-2 border-r border-gray-300">Establecimiento</th>
                                        <th className="px-3 py-2 border-r border-gray-300">Pto. Emisión</th>
                                        <th className="px-3 py-2 border-r border-gray-300">Secuencial</th>
                                        <th className="px-3 py-2 border-r border-gray-300">Error</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {erroresDetalles.map((detalle, index) => (
                                        <tr key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap">{detalle.LINEA}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{detalle.ESTABLECIMIENTO}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{detalle.PTOEMISION}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{detalle.SECUENCIAL}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{detalle.ERROR}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ModalErroresExcel;
