import React, { useState, useContext, useMemo } from "react";
import { EmitirFacturaContext } from "../../EmisionElectronico/EmitirFactura/EmitirFacturaProvider";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import {
    TableStyle,
    TableStyleDiv,
    TableStyleTBody,
    TableStyleTHead,
} from "../../../features/Constantes";

const FacturasTabla = () => {
    const { groupedData } = useContext(EmitirFacturaContext);
    const { ErrorToast } = useGlobalToastify();

    const [pagina, setPagina] = useState(1);
    const [elementosPorPagina, setElementosPorPagina] = useState(25);

    const detallesFlat = useMemo(() => {
        return groupedData.flatMap((factura) =>
            factura.DETALLES.map((detalle) => ({
                ...factura,
                ...detalle,
            }))
        );
    }, [groupedData]);

    const totalPaginas = useMemo(
        () => Math.ceil(detallesFlat.length / elementosPorPagina),
        [detallesFlat, elementosPorPagina]
    );

    const handleClickNextPage = () => {
        if (pagina < totalPaginas) setPagina(pagina + 1);
    };

    const handleClickPreviousPage = () => {
        if (pagina > 1) setPagina(pagina - 1);
    };

    const handleClickFirstPage = () => setPagina(1);
    const handleClickLastPage = () => setPagina(totalPaginas);

    const handleChangeElementosPorPagina = (e) => {
        setElementosPorPagina(Number(e.target.value));
        setPagina(1);
    };

    const getPaginatedData = (data) => {
        const startIndex = (pagina - 1) * elementosPorPagina;
        return data.slice(startIndex, startIndex + elementosPorPagina);
    };

    const dataToPaginate = getPaginatedData(detallesFlat);

    if (groupedData.length === 0) {
        ErrorToast("No se encontraron datos.");
        return null;
    }

    const totalFacturas = groupedData.length;
    const totalLineas = detallesFlat.length;

    const totales = detallesFlat.reduce(
        (acc, detalle) => {
            acc.cantidad += detalle.CANTIDAD || 0;
            acc.precioUnitario += detalle.PRECIOUNITARIO || 0;
            acc.totalDescuento += detalle.DESCUENTO || 0;
            acc.totalSinImpuesto += detalle.TOTALSINIMPUESTO || 0;
            acc.totalValorIVA += detalle.VALORIVA || 0;
            acc.totalConIVA += detalle.TOTAL || 0;
            return acc;
        },
        {
            cantidad: 0,
            precioUnitario: 0,
            totalDescuento: 0,
            totalSinImpuesto: 0,
            totalValorIVA: 0,
            totalConIVA: 0,
        }
    );

    const tableHeightClass = groupedData.length < 5 ? "h-auto" : "h-[65vh]";

    return (
        <div className="my-6">
            <div className="flex items-center mb-4">
                <h1 className="mr-2 text-gray-700">Mostrar</h1>
                <select
                    value={elementosPorPagina}
                    onChange={handleChangeElementosPorPagina}
                    className="border-gray-300 border px-2 py-1 rounded-md text-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                    {[25, 50, 75, 100, 200, 300, 400, 500, 1000].map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <h1 className="ml-2 text-gray-700">Registros</h1>
            </div>

            <h1 className="font-bold text-xl pb-4">Detallado</h1>
            <div className={`${tableHeightClass} ${TableStyleDiv}`}>
                <table className={TableStyle} >
                    <thead className={TableStyleTHead + " bg-[#d9d9d9] text-xs"}>
                        <tr>
                            {[
                                'Establecimiento', 'Pto Emisión', 'Secuencial', 'Fecha Emisión',
                                'Razón Social Cliente', 'N. Identificación Cliente', 'Dirección Cliente',
                                'Correo Cliente', 'Guia Remisión', 'Código Principal', 'Código Auxiliar', 'Descripción',
                                'Cantidad', 'Precio Unitario', 'Descuento', 'Total Sin Impuesto',
                                'Porcentaje IVA', 'Valor IVA', 'Total con IVA', 'Inf. Adicional Producto 1',
                                'Inf. Adicional Producto Valor 1', 'Inf. Adicional Producto 2',
                                'Inf. Adicional Producto Valor 2', 'Inf. Adicional Producto 3',
                                'Inf. Adicional Producto Valor 3', 'Código Forma Pago',
                                'Plazo Forma Pago', 'Unidad Tiempo Forma Pago', 'Inf. Adicional Factura Nombre 1',
                                'Inf. Adicional Factura Valor 1', 'Inf. Adicional Factura Nombre 2',
                                'Inf. Adicional Factura Valor 2', 'Inf. Adicional Factura Nombre 3',
                                'Inf. Adicional Factura Valor 3', 'Inf. Adicional Factura Nombre 4',
                                'Inf. Adicional Factura Valor 4', 'Inf. Adicional Factura Nombre 5',
                                'Inf. Adicional Factura Valor 5', 'Inf. Adicional Factura Nombre 6',
                                'Inf. Adicional Factura Valor 6', 'Inf. Adicional Factura Nombre 7',
                                'Inf. Adicional Factura Valor 7', 'Inf. Adicional Factura Nombre 8',
                                'Inf. Adicional Factura Valor 8', 'Inf. Adicional Factura Nombre 9',
                                'Inf. Adicional Factura Valor 9', 'Inf. Adicional Factura Nombre 10',
                                'Inf. Adicional Factura Valor 10', 'Inf. Adicional Factura Nombre 11',
                                'Inf. Adicional Factura Valor 11', 'Inf. Adicional Factura Nombre 12',
                                'Inf. Adicional Factura Valor 12', 'Inf. Adicional Factura Nombre 13',
                                'Inf. Adicional Factura Valor 13', 'Inf. Adicional Factura Nombre 14',
                                'Inf. Adicional Factura Valor 14', 'Inf. Adicional Factura Nombre 15',
                                'Inf. Adicional Factura Valor 15'
                            ].map((header) => (
                                <th
                                    key={header}
                                    className="px-4 py-2 border border-gray-300"
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={TableStyleTBody}>
                        {dataToPaginate.map((detalle, index) => (
                            <tr
                                key={index}
                                className="hover:bg-gray-100 border-b border-gray-300"
                            >
                                <td className="px-4 py-2 text-xs">{detalle.ESTABLECIMIENTO || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.PTOEMISION || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.SECUENCIAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.FECHAEMISION || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.CLIENTE || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.IDENTIFICACIONCLIENTE || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.DIRECCIONCLIENTE || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.CORREOCLIENTE || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.GUIAREMISION || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.CODIGOPRINCIPAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.CODIGOAUXILIAR || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.DESCRIPCION || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.CANTIDAD || ''}</td>
                                <td className="px-4 py-2 text-xs">${detalle.PRECIOUNITARIO ? detalle.PRECIOUNITARIO.toFixed(3) : 0 || ''}</td>
                                <td className="px-4 py-2 text-xs">$ {detalle.DESCUENTO == 0 ? 0 : detalle.DESCUENTO.toFixed(3)}</td>
                                <td className="px-4 py-2 text-xs">$ {detalle.TOTALSINIMPUESTO.toFixed(3) || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.TARIFAIVA || 0}</td>
                                <td className="px-4 py-2 text-xs">$ {detalle.VALORIVA == 0 ? 0 : detalle.VALORIVA.toFixed(3) || ''}</td>
                                <td className="px-4 py-2 text-xs">$ {detalle.TOTAL.toFixed(3) || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONALES?.[0]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONALES?.[0]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONALES?.[1]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONALES?.[1]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONALES?.[2]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONALES?.[2]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.FORMASPAGO[0]?.IDFORMAPAGOSRI || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.FORMASPAGO[0]?.PLAZO || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.FORMASPAGO[0]?.UNIDADTIEMPO || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[0]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[0]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[1]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[1]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[2]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[2]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[3]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[3]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[4]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[4]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[5]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[5]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[6]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[6]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[7]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[7]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[8]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[8]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[9]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[9]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[10]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[10]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[11]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[11]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[12]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[12]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[13]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[13]?.VALORINFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[14]?.INFADICIONAL || ''}</td>
                                <td className="px-4 py-2 text-xs">{detalle.ADICIONAL?.[14]?.VALORINFADICIONAL || ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="mt-6 p-4 rounded-md">
                <h1 className="font-bold text-xl pb-4">Consolidado</h1>
                <table className={TableStyle}>
                    <thead className={TableStyleTHead + " bg-[#d9d9d9]"}>
                        <tr>
                            <th>Cantidad</th>
                            <th>Precio Unitario</th>
                            <th>Total Descuento</th>
                            <th>Total Sin Impuesto</th>
                            <th>Total Valor IVA</th>
                            <th>Total con IVA</th>
                        </tr>
                    </thead>
                    <tbody className={TableStyleTBody}>
                        <tr>
                            <td>{totales.cantidad}</td>
                            <td>${totales.precioUnitario.toFixed(2)}</td>
                            <td>${totales.totalDescuento.toFixed(2)}</td>
                            <td>${totales.totalSinImpuesto.toFixed(2)}</td>
                            <td>${totales.totalValorIVA.toFixed(2)}</td>
                            <td>${totales.totalConIVA.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex items-center justify-between mt-4">
                <span className="text-gray-800">
                    Mostrando{" "}
                    <span className="font-bold">
                        {(pagina - 1) * elementosPorPagina + 1}
                    </span>{" "}
                    -{" "}
                    <span className="font-bold">
                        {pagina * elementosPorPagina > totalLineas
                            ? totalLineas
                            : pagina * elementosPorPagina}
                    </span>{" "}
                    líneas de{" "}
                    <span className="font-bold">
                        {totalFacturas}
                    </span>{" "}
                    {totalFacturas === 1 ? "factura" : "facturas"}
                </span>

                <div className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <PaginationButton
                        label="<<"
                        onClick={handleClickFirstPage}
                        disabled={pagina === 1}
                        first
                    />
                    <PaginationButton
                        label="<"
                        onClick={handleClickPreviousPage}
                        disabled={pagina === 1}
                    />
                    <PaginationButton
                        label=">"
                        onClick={handleClickNextPage}
                        disabled={pagina === totalPaginas}
                    />
                    <PaginationButton
                        label=">>"
                        onClick={handleClickLastPage}
                        disabled={pagina === totalPaginas}
                        last
                    />
                </div>
            </div>

        </div>
    );
};

const PaginationButton = ({ label, onClick, disabled, first, last }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`
                ${first ? 'rounded-l-md' : ''}
                ${last ? 'rounded-r-md' : ''}
                ${disabled ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white hover:bg-gray-50'}
                px-3 py-2 border border-gray-300 text-sm font-medium
                ${disabled ? 'text-gray-400' : 'text-gray-500'}
            `}
        >
            {label}
        </button>
    );
};


export default FacturasTabla;
