import { useContext, useState, useEffect } from "react";
import { EmitirDocContext } from "../../EmisionElectronico/EmitirRetencion/EmitirDocProvider";

const ITEMS_PER_PAGE = 10;

const ResultadoTabla = () => {
    const {
        groupedData, secuencialesExistentes, secuencialesProblemas
    } = useContext(EmitirDocContext);


    const [TipoView, setTipoView] = useState("EXITOSAS");
    const [currentPageExitosa, setCurrentPageExitosa] = useState(1);
    const [currentPageRepetida, setCurrentPageRepetida] = useState(1);
    const [currentPageError, setCurrentPageError] = useState(1);



    // Filtrar las filas con problemas
    const rowsWithProblems = groupedData
        .filter(row =>
            secuencialesProblemas.some(problem => {
                const [problemEst, problemPto, problemSec] = problem.retencion.split('-');
                return problemEst === String(Number(row.ESTABLECIMIENTO)) && problemPto === String(Number(row.PTOEMISION)) && problemSec === String(Number(row.SECUENCIAL));
            })
        )
        .map(row => {
            const problema = secuencialesProblemas.find(problem => {
                const [problemEst, problemPto, problemSec] = problem.retencion.split('-');
                return problemEst === String(Number(row.ESTABLECIMIENTO)) && problemPto === String(Number(row.PTOEMISION)) && problemSec === String(Number(row.SECUENCIAL));
            });
            return {
                ...row,
                mensaje: problema ? problema.mensaje : ''
            };
        });


    // Filtrar las filas que ya existen
    const rowsWithExisting = groupedData.filter(row =>
        secuencialesExistentes.some(existente => {
            const [existEst, existPto, existSec] = existente.split('-');
            return existEst === String(Number(row.ESTABLECIMIENTO)) && existPto === String(Number(row.PTOEMISION)) && existSec === String(Number(row.SECUENCIAL));
        })
    );


    // Filtrar las filas exitosas
    const rowsExitosas = groupedData.filter(row =>
        !secuencialesExistentes.some(existente => {
            const [existEst, existPto, existSec] = existente.split('-');
            return existEst === String(Number(row.ESTABLECIMIENTO)) && existPto === String(Number(row.PTOEMISION)) && existSec === String(Number(row.SECUENCIAL));
        }) &&
        !secuencialesProblemas.some(problem => {
            const [problemEst, problemPto, problemSec] = problem.retencion.split('-');
            return problemEst === String(Number(row.ESTABLECIMIENTO)) && problemPto === String(Number(row.PTOEMISION)) && problemSec === String(Number(row.SECUENCIAL));
        })
    );



    const getPaginatedData = (data, currentPage) => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return data.slice(startIndex, endIndex);
    };

    const handlePageChange = (type, pageNumber) => {
        if (type === "EXITOSAS") setCurrentPageExitosa(pageNumber);
        if (type === "REPETIDAS") setCurrentPageRepetida(pageNumber);
        if (type === "ERROR") setCurrentPageError(pageNumber);
    };

    const styleInactivo =
        "inline-flex px-4 py-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group";
    const styleActivo =
        "inline-flex px-4 py-2 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group";

    const totalPagesExitosa = Math.ceil(rowsExitosas.length / ITEMS_PER_PAGE);
    const totalPagesRepetida = Math.ceil(rowsWithExisting.length / ITEMS_PER_PAGE);
    const totalPagesError = Math.ceil(rowsWithProblems.length / ITEMS_PER_PAGE);


    const renderTable = (data, type) => (
        <div>
            <h3 className="text-xl font-semibold mb-4">COMPROBANTES DE RETENCIONES {type}</h3>
            {data.length > 0 ? (
                <div className="h-[65vh]">
                    <table className="min-w-full divide-y divide-gray-200 text-center">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                            <tr>
                                <th className="py-2 px-3 border-r border-gray-300">Establecimiento</th>
                                <th className="py-2 px-3 border-r border-gray-300">Punto Emisión</th>
                                <th className="py-2 px-3 border-r border-gray-300">Secuencial</th>
                                {type === "ERROR" && <th className="py-2 px-3 border-r border-gray-300">Mensaje</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {getPaginatedData(data, type === "EXITOSAS" ? currentPageExitosa : type === "REPETIDAS" ? currentPageRepetida : currentPageError).map((row, index) => {
                                const rowData = {
                                    Establecimiento: row.ESTABLECIMIENTO || '',
                                    PtEmision: row.PTOEMISION || '',
                                    secuencial: row.SECUENCIAL || '',
                                    mensaje: row.mensaje || ''
                                };

                                return (
                                    <tr key={index} className="bg-white border-b text-[0.813rem] hover:bg-gray-50">
                                        <td className="py-2 px-3 border-r border-gray-300">{rowData.Establecimiento}</td>
                                        <td className="py-2 px-3 border-r border-gray-300">{rowData.PtEmision}</td>
                                        <td className="py-2 px-3 border-r border-gray-300">{rowData.secuencial}</td>
                                        {type === "ERROR" && <td className="py-2 px-3 border-r border-gray-300">{rowData.mensaje}</td>}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="flex flex-row flex-wrap gap-2 mt-8 justify-end">
                        <h1 className="font-bold text-xl mb-4">Total: {data.length} de {groupedData.length} Retenciones</h1>
                    </div>
                    <div className="flex flex-col items-center mt-8">
                        <div className="flex gap-2">
                            <button
                                onClick={() => handlePageChange(type, type === "EXITOSAS" ? currentPageExitosa - 1 : type === "REPETIDAS" ? currentPageRepetida - 1 : currentPageError - 1)}
                                disabled={type === "EXITOSAS" ? currentPageExitosa === 1 : type === "REPETIDAS" ? currentPageRepetida === 1 : currentPageError === 1}
                                className="px-4 py-2 bg-gray-200 rounded"
                            >
                                Anterior
                            </button>
                            <span className="px-4 py-2">{type === "EXITOSAS" ? currentPageExitosa : type === "REPETIDAS" ? currentPageRepetida : currentPageError} / {type === "EXITOSAS" ? totalPagesExitosa : type === "REPETIDAS" ? totalPagesRepetida : totalPagesError}</span>
                            <button
                                onClick={() => handlePageChange(type, type === "EXITOSAS" ? currentPageExitosa + 1 : type === "REPETIDAS" ? currentPageRepetida + 1 : currentPageError + 1)}
                                disabled={type === "EXITOSAS" ? currentPageExitosa === totalPagesExitosa : type === "REPETIDAS" ? currentPageRepetida === totalPagesRepetida : currentPageError === totalPagesError}
                                className="px-4 py-2 bg-gray-200 rounded"
                            >
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex items-center justify-center">
                    <p>No existen comprobantes de Retención {type.toLowerCase()}</p>
                </div>
            )}
        </div>
    );


    return (
        <div className="py-4">
            <h2 className="font-bold text-2xl mb-6 text-gray-800 dark:text-white pb-2 border-b-gray-600 border-b">Resultado</h2>
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-gray-500 justify-start pb-4">
                {rowsExitosas.length > 0 && (
                    <li className="mr-2 flex items-center">
                        <span className={`w-2 h-2 rounded-full bg-green-500`}></span>
                        <button
                            onClick={() => setTipoView("EXITOSAS")}
                            className={TipoView === "EXITOSAS" ? styleActivo : styleInactivo}
                        >
                            EXITOSAS
                        </button>
                    </li>
                )}

                {rowsWithExisting.length > 0 && (
                    <li className="mr-2 flex items-center">
                        <span className={`w-2 h-2 rounded-full bg-yellow-400`}></span>
                        <button
                            onClick={() => setTipoView("REPETIDAS")}
                            className={TipoView === "REPETIDAS" ? styleActivo : styleInactivo}
                        >
                            REPETIDAS
                        </button>
                    </li>
                )}

                {rowsWithProblems.length > 0 && (
                    <li className="mr-2 flex items-center">
                        <span className={`w-2 h-2 rounded-full bg-red-500`}></span>
                        <button
                            onClick={() => setTipoView("ERROR")}
                            className={TipoView === "ERROR" ? styleActivo : styleInactivo}
                        >
                            ERROR
                        </button>
                    </li>
                )}
            </ul>

            {TipoView === "EXITOSAS" && renderTable(rowsExitosas, "EXITOSAS")}
            {TipoView === "REPETIDAS" && (<div>

                <p className="py-2">Los comprobantes electrónicos que estás intentando enviar al SRI ya han sido registrados y autorizados en fechas anteriores. Por favor, verifique y corrija las secuencias para asegurarte de que sean únicos antes de volver a enviarlos al SRI.</p>
                {renderTable(rowsWithExisting, "REPETIDAS")}
            </div>)}
            {TipoView === "ERROR" && renderTable(rowsWithProblems, "ERROR")}
        </div>
    );
};

export default ResultadoTabla;