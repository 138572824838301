import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DashboarItem from "./DashboarItem";
import ModalInfo from "./ModalInfo";
import factura from "../../img/contribuyente.png";
import ModalComunicado from "./ModalComunicado";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import Select from "react-select";
import CardDasboard from "../../features/components/CardDasboard";
import { DocumentosEmitidosGraf } from "./Indicadores/DocumentosEmitidosGraf";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { getList } from "../../services";
import { set } from "react-hook-form";
import { Spinner } from "../../shared/components/Spinners/Spinner";

const Dashboard = () => {
  const permisos = useSelector((store) => store.permisosEmpresa.permisos);
  const [IsDownSRI, setIsDownSRI] = useState(process.env.REACT_APP_SRI_IS_DOWN);
  const [Comunicado, setComunicado] = useState(
    process.env.REACT_APP_COMUNICATE_INTUITO
  );
  const [dashBoardState, setDashBoardState] = useState("accesos");
  const empresa = useSelector((store) => store.empresa.empresa);
  const splideRef = useRef(null);
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  useEffect(() => {
    if (splideRef.current) {
      const splideInstance = splideRef.current.splide;
      const prevArrow = document.querySelector(".custom-prev-arrow");
      const nextArrow = document.querySelector(".custom-next-arrow");
      if (prevArrow && nextArrow) {
        prevArrow.addEventListener("click", () => splideInstance.go("-1"));
        nextArrow.addEventListener("click", () => splideInstance.go("+1"));
      }
    }
  }, []);

  // useEffect(() => {
  //   if (IsDownSRI === "1") {
  //     document.getElementById("SriIsDownModalButton").click();
  //     setIsDownSRI("0");
  //   }
  // }, []);

  useEffect(() => {
    if (Comunicado === "1") {
      document.getElementById("ComunicadoButton").click();
      setComunicado("0");
    }
  }, []);

  const getCurrentMonthRange = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // Mes actual (0-11)

    // Primer día del mes actual
    const fechaInicio = new Date(year, month, 1).toISOString().split("T")[0]; // YYYY-MM-DD

    // Último día del mes actual
    const fechaFin = new Date(year, month + 1, 0).toISOString().split("T")[0]; // YYYY-MM-DD

    return { fechaInicio, fechaFin };
  };

  const initialForm = getCurrentMonthRange();
  const [form, setForm] = useState({});
  const [chartData, setChartData] = useState();
  const [empty,] = useState({
    labels: [],
    datasets: [
      {
        label: "Cantidad Total de ventas",
        data: [],
        backgroundColor: ["#00314D", "#d5d6d7"],
        borderColor: ["white", "white"],
        borderWidth: 1,
      },
    ],
  });

  const monthOptions = [
    { value: "01", label: "Enero" },
    { value: "02", label: "Febrero" },
    { value: "03", label: "Marzo" },
    { value: "04", label: "Abril" },
    { value: "05", label: "Mayo" },
    { value: "06", label: "Junio" },
    { value: "07", label: "Julio" },
    { value: "08", label: "Agosto" },
    { value: "09", label: "Septiembre" },
    { value: "10", label: "Octubre" },
    { value: "11", label: "Noviembre" },
    { value: "12", label: "Diciembre" },
  ];

  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");

  const defaultMonth = monthOptions.find(
    (option) => option.value === currentMonth
  );

  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
  const [dateRange, setDateRange] = useState(initialForm);
  const [isOpen, setIsOpen] = useState(false);


  const calculateDateRange = (monthOption) => {
    const year = new Date().getFullYear();
    const month = monthOption.value;

    const fechaInicio = new Date(year, month - 1, 1);
    const fechaFin = new Date(year, month, 0);

    setDateRange({
      fechaInicio: fechaInicio.toISOString().split("T")[0],
      fechaFin: fechaFin.toISOString().split("T")[0],
    });
  };

  useEffect(() => {
    calculateDateRange(defaultMonth);
    apiData();
  }, [empresa, selectedMonth]);

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    calculateDateRange(selectedOption);
  };
  const apiData = async () => {
    try {
      setLoading(true);
      let response = await getList(
        `api/dashboard/Reportes?idEmpresa=${empresa.idEmpresa}&FechaInicio=${dateRange.fechaInicio}&FechaFin=${dateRange.fechaFin}`
      );
      setForm(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (form.dias && form.dias.length > 0) {
      const labels = form.dias.map((item) => {
        const date = new Date(item.dia + "T00:00:00");
        return date.getUTCDate();
      });

      const totals = form.dias.map((item) => item.total);
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Total Ventas",
            data: totals,
            backgroundColor: ["#00314D"],
            borderColor: ["white", "white"],
            borderWidth: 1,
            cutout: "65%",
          },
        ],
      };

      setChartData(data);
    } else {
      const labels = [];
      const totals = [];
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Cantidad Total de ventas",
            data: totals,
            backgroundColor: ["#00314D", "#d5d6d7"],
            borderColor: ["white", "white"],
            borderWidth: 1,
          },
        ],
      };
      setChartData(data);
    }
  }, [form]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 0,
        },
        title: {
          display: true,
          text: 'Días',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Dólar',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (IsDownSRI === "1") {
      toggleModal();
    }
  },);

  useEffect(() => {
    if (IsDownSRI === "1") {
      toggleModal();
    }
  },);


  return (
    <div className="lg:mx-4 md:mx-4 lg:my-2 md:my-2">
      <ModalInfo
        isOpen={isOpen}
        toggleModal={toggleModal}
      />
      <ModalComunicado />
      <button
        className=" hidden hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target="#SriIsDownModal"
        id="SriIsDownModalButton"
      ></button>
      <button
        className=" hidden hover:bg-gray-200  dark:ho|ver:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target="#Comunicado"
        id="ComunicadoButton"
      ></button>

      <div className="pt-8 flex flex-col gap-3 dark:bg-gray-900  sm:rounded-lg sm:p-6">
        <h3 className="text-2xl bg-white p-8 font-medium text-center leading-6 rounded-lg text-blue-600 dark:text-white select-none">
          <span className="text-xl text-[#00314D]">
            Bienvenido(a) al facturador de{" "}
            <span className="text-black">{empresa.razonSocial}</span>
          </span>
        </h3>
        <div className=" my-3 bg-white dark:bg-gray-700 rounded-lg ">
          <nav className="flex flex-row w-full h-full text-[14px]">
            <button
              className={`border border-t-4 border-t-white border-l-0 border-r-0 flex items-center ${dashBoardState === "accesos" && " border-t-[#00314D] border-b-0"
                } p-4 pl-8 pr-8 justify-center h-full`}
              onClick={() => setDashBoardState("accesos")}
            >
              Accesos Rapidos
            </button>
            <button
              className={`border border-t-4 border-r-0 border-t-white flex items-center ${dashBoardState === "indicadores" &&
                "border-t-[#00314D] border-b-0 border-r"
                } p-4 pl-8 pr-8 justify-center h-full`}
              onClick={() => setDashBoardState("indicadores")}
            >
              Indicadores
            </button>
            <div className="border-b flex-1 w-full"></div>
          </nav>
          {dashBoardState === "accesos" && (
            <section className="p-4">
              <div className="my-4 ">
                <div className="border-b border-b-gray-400/50  ">
                  <p className="font-semibold text-lg mx-6 dark:text-white">
                    ¿Qué deseas hacer ahora?
                  </p>
                  <div className={`grid grid-cols-1 gap-6 my-auto p-6  sm:grid-cols-1 md:grid-cols-2  ${Object.keys(empresa).length === 0 ? "lg:grid-cols-2" : "lg:grid-cols-4"}  `}>
                    {Object.keys(empresa).length === 0 ? <>
                      <DashboarItem
                        isActive={true}
                        title={"Consultar de documentos electrónicos Recibidos"}
                        route={"/emision/reporte/cliente"}
                        svg={
                          <svg>
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5" />
                            <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                            <path d="M18.5 19.5l2.5 2.5" />
                          </svg>
                        }
                      />
                      <DashboarItem
                        isActive={true}
                        title={"Editar Usuario"}
                        route={"/emision/reporte/editar-usuario"}
                        svg={
                          <svg>
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                            <path d="M16 19h6" />
                            <path d="M19 16v6" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                          </svg>
                        }
                      />
                    </> :
                      <>
                        <DashboarItem
                          isActive={
                            permisos.find((el) => el.codigo === "13") ? true : false
                          }
                          title={"Crear una factura electrónica"}
                          route={"/emision/generar/factura"}
                          svg={
                            <svg>
                              <path d="M20 11.08V8l-6-6H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h6" />
                              <path d="M14 3v5h5M18 21v-6M15 18h6" />
                            </svg>
                          }
                        />
                        <DashboarItem
                          isActive={
                            permisos.find((el) => el.codigo === "03") ? true : false
                          }
                          title={"Consultar de documentos electrónicos"}
                          route={"/emision/reporte/general"}
                          svg={
                            <svg>
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                              <path d="M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5" />
                              <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                              <path d="M18.5 19.5l2.5 2.5" />
                            </svg>
                          }
                        />
                        <DashboarItem
                          isActive={
                            permisos.find((el) => el.codigo === "12") ? true : false
                          }
                          title={"Registrar contribuyentes"}
                          route={"/emision/reporte/contribuyentes"}
                          svg={
                            <svg>
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                              <path d="M16 19h6" />
                              <path d="M19 16v6" />
                              <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                            </svg>
                          }
                        />

                        <DashboarItem
                          isActive={
                            permisos.find((el) => el.codigo === "23") ? true : false
                          }
                          title={"Crear Producto/Servicios"}
                          route={"/emision/reporte/servicios"}
                          svg={
                            <svg>
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M12.5 21h-3.926a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304h11.339a2 2 0 0 1 1.977 2.304l-.263 1.708" />
                              <path d="M16 19h6" />
                              <path d="M19 16v6" />
                              <path d="M9 11v-5a3 3 0 0 1 6 0v5" />
                            </svg>
                          }
                        /></>
                    }

                  </div>
                </div>
              </div>

              <div className="relative ">
                <button className=" ml-0 custom-prev-arrow lg:flex   hidden justify-center absolute top-[45%] z-10 border p-2  rounded-lg   text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-white text-black  active:bg-gray-200 active:text-white/80">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <p className="font-semibold text-lg mx-6 dark:text-white">
                  Mantente al dia con las novedades de Firma y Facturador
                </p>

                <Splide
                  ref={splideRef}
                  className="my-carousel m-0 p-0  bg-transparent lg:mx-4 md:px-4  "
                  options={{
                    rewind: true,
                    autoplay: true,
                    loop: true,
                    smartSpeed: 1000,
                    padding: "0rem",
                    margin: "0rem",
                    gap: "2rem",
                    pagination: true,
                    arrows: false,
                    perPage: 2,
                    breakpoints: {
                      640: {
                        perPage: 1,
                      },
                      768: {
                        perPage: 2,
                      },
                      1024: {
                        perPage: 2,
                      },
                    },
                  }}
                >
                  <SplideSlide>
                    <CardDasboard
                      text={"¿Cómo firmar desde tú móvil?"}
                      link={
                        "https://www.instagram.com/reel/Cvh2tket47h/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      }
                      description={
                        "Firma documentos digitales de manera fácil y segura desde tu dispositivo móvil."
                      }
                    />
                  </SplideSlide>
                  <SplideSlide>
                    <CardDasboard
                      text={"Actualiza tu firma en el facturador"}
                      link={
                        "https://www.instagram.com/reel/C1KrdGKRsf1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      }
                      description={
                        "Garantiza la validez y seguridad de tus transacciones digitales manteniendo actualizada tu firma electrónica."
                      }
                    />
                  </SplideSlide>
                  <SplideSlide>
                    <CardDasboard
                      text={"¿Cómo realizar facturas electrónicas?"}
                      link={
                        "https://www.instagram.com/reel/Cvds8XitFXm/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      }
                      description={
                        "Aprende a generar facturas electrónicas con autorización del SRI."
                      }
                    />
                  </SplideSlide>
                  <SplideSlide>
                    <CardDasboard
                      text={"Anulación de facturas electrónicas"}
                      link={
                        "https://www.instagram.com/reel/CxTNIPBrxNq/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      }
                      description={
                        "Anula tus facturas previamente autorizadas en la plataforma del SRI, corrigiendo errores contables."
                      }
                    />
                  </SplideSlide>
                </Splide>
                <button className=" custom-next-arrow lg:flex hidden justify-center absolute top-[45%] right-0  z-10 border p-2  rounded-lg   text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-white text-black  active:bg-gray-200 active:text-white/80">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 rotate-180"
                  >
                    <path
                      fillRule="evenodd"
                      d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </section>
          )}
          {dashBoardState === "indicadores" && (
            <main className="flex flex-col gap-3 py-4 ">
              <nav className="p-4 bg-gray-50 text-[14px]">
                <div className="flex flex-row ml-8 gap-4 items-center">
                  <h1>Seleccione el mes:</h1>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    styles={{
                      control: (styles) => ({ ...styles, width: "200px" }),
                      clearIndicator: (styles) => ({
                        ...styles,
                        display: "none",
                      }),
                    }}
                    options={monthOptions}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                  />
                </div>
              </nav>
              <div className="flex-1 px-8 py-2 grid gap-3 md:grid-cols-4 md:grid-rows-3 grid-cols-1">
                <DocumentosEmitidosGraf data={form} />
                <section className=" col-span-1 p-4 border flex flex-col gap-4 ">
                  <h1 className="text-lg font-semibold ml-3">Total</h1>
                  <div className="flex flex-col items-center justify-center gap-2 p-5 rounded-xl w-full h-full relative bg-gray-50 text-white">
                    <p className="text-black text-3xl font-semibold">
                      {form.cantidadEmitidos}
                    </p>
                    <p className="text-gray-500">comprobantes emitidos</p>
                  </div>
                </section>
                <section className=" md:row-span-2 md:col-span-2 p-4 flex flex-col gap-3 border">
                  {!loading ? (
                    <>
                      <h1 className="text-lg font-semibold p-4 pl-6">Ventas Totales</h1>
                      <div className="chart-container">
                        {chartData && chartData.labels?.length > 0 ? (
                          <Bar data={chartData} options={options} />
                        ) : (
                          <Bar data={empty} options={options} /> 
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="h-full flex justify-center items-center">
                      <Spinner />
                    </div>
                  )}
                </section>
              </div>
            </main>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
